import { LoginForm } from "../components/form/LoginForm";
import Logo from "../assets/img/logos/divixBlanco.png";

import { useTranslation } from "react-i18next";
import { LegalModal } from "../components/modals/LegalModal";
import { useLayoutEffect } from "react";
import { useAuth } from "../context/auth-context";
import { useNavigate } from "react-router-dom";
import { getTokenPayload } from "../utils/token";

export function Login() {
  const { t } = useTranslation();
  const { isAuthenticated, token } = useAuth();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (isAuthenticated && token) {
      const isFirstLogin = getTokenPayload(token).isFirstLogin;
      if (isFirstLogin) {
        navigate("/setup-branch");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isAuthenticated, navigate, token]);

  return (
    <div className="container h-screen relative grid flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
      <LegalModal />
      {/* Logo section */}
      <div className="relative hidden h-full flex-col p-10 text-white lg:flex lg:justify-center lg:items-center bg-dark-gradient-to-b">
        <img className="w-32 mx-auto" src={Logo} alt="Divix Logo" />
        <h1 className="mt-6 text-3xl font-light tracking-wide">
          {t("login.welcome")}
        </h1>
      </div>

      {/* Login section */}
      <div className="lg:p-4">
        <div className="mx-auto text-white flex w-full border rounded-xl flex-col justify-center space-y-4 py-4 sm:w-[400px] bg-dark-gradient-to-b">
          <div className="flex space-y-2 mx-auto">
            <svg
              width="101"
              height="101"
              viewBox="-1.68 -1.68 27.36 27.36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#EF0BB8"
            >
              <g
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#EF0BB8"
                strokeWidth=".312"
              >
                <circle opacity=".5" cx="12" cy="9" r="3" />
                <circle cx="12" cy="12" r="10" />
                <path
                  opacity=".5"
                  d="M17.97 20c-.16-2.892-1.045-5-5.97-5s-5.81 2.108-5.97 5"
                />
              </g>
              <g strokeWidth=".312">
                <circle opacity=".5" cx="12" cy="9" r="3" />
                <circle cx="12" cy="12" r="10" />
                <path
                  opacity=".5"
                  d="M17.97 20c-.16-2.892-1.045-5-5.97-5s-5.81 2.108-5.97 5"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          </div>

          <div className="grid py-4 gap-6">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}
