interface SucursalProps {
  fill?: string;
}

export function Sucursal({
  fill = "#000"
}: SucursalProps) {
  return (
    <svg
      fill={fill}
      height="28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 279.131 279.131"
    >
      <path d="m276.346 109.821-93.71-70.666a7 7 0 0 0-8.43 0l-15.405 11.617-15.405-11.617a7 7 0 0 0-8.43 0l-15.021 11.327-15.021-11.327a7 7 0 0 0-8.43 0l-93.71 70.666A7.003 7.003 0 0 0 0 115.41v118.977a7 7 0 0 0 7 7h265.131a7 7 0 0 0 7-7V115.41a7.001 7.001 0 0 0-2.785-5.589zm-11.215 117.566h-25.238V115.41a7 7 0 0 0-2.785-5.589l-66.68-50.282 7.993-6.028 86.71 65.388v108.488zm-63.71 0V115.41a7 7 0 0 0-2.785-5.589L131.572 59.25l7.61-5.738 86.711 65.388v108.488h-24.472zm-89.247-.001H89.246v-50.76h22.928v50.76zm14 0v-57.76a7 7 0 0 0-7-7H82.246a7 7 0 0 0-7 7v57.76H14V118.898l86.71-65.388 86.711 65.388v108.488h-61.247z" />
    </svg>
  );
}