import { Group } from "../types";
import BaseService from "./base-service";
import {
  AddGroupReq,
  DeleteGroupRedistributionReq,
  EditGroupReq,
  SetPercentageReq,
} from "./dto/request/groups-request-dto";
import { RequestDto } from "./dto/request/request-dto";
import {
  DeleteGroupRedResponse,
  DeleteGroupResponse,
  EditGroupResponse,
} from "./dto/response/groups-response-dto";

export class GroupService extends BaseService {
  private resource = "/api/groups";

  async getGroups(
    branchId: number,
    onSuccess: (data: Group[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(`${this.resource}/${branchId}`, {});
    await this.doGet<Group[]>(reqParams, onSuccess, onError);
  }

  async addGroup(
    params: AddGroupReq,
    onSuccess: (data: Group[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource, params);
    await this.doPost<Group[]>(reqParams, onSuccess, onError);
  }

  async setPercentages(
    params: SetPercentageReq,
    onSuccess: (data: string) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource, params);
    await this.doPatch<string>(reqParams, onSuccess, onError);
  }

  async editGroup(
    params: EditGroupReq,
    onSuccess: (data: EditGroupResponse[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/edit-name", params);
    await this.doPatch<EditGroupResponse[]>(reqParams, onSuccess, onError);
  }

  async deleteGroup(
    groupId: number,
    onSuccess: (data: DeleteGroupResponse[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(`${this.resource}/${groupId}`, {});
    await this.doDelete<DeleteGroupResponse[]>(reqParams, onSuccess, onError);
  }

  async deleteGroupRedistribution(
    params: DeleteGroupRedistributionReq,
    onSuccess: (data: DeleteGroupRedResponse[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource, params);
    await this.doDelete<DeleteGroupRedResponse[]>(
      reqParams,
      onSuccess,
      onError
    );
  }
}
