import { Button, CircularProgress } from "@mui/material";
import React from "react";

interface ButtonFormProps {
  loading: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  bgColor?: string;
}

export function ButtonForm({
  loading,
  children,
  disabled = false,
  bgColor = "#EF0BB8",
}: ButtonFormProps) {
  return (
    <Button
      disabled={loading || disabled}
      type="submit"
      sx={{
        padding: "4px 8px",
        width: "100%",
        background: bgColor,
        borderRadius: "16px",
        color: "white",
        textTransform: "none",
        fontFamily: "Montserrat",
        fontWeight: 300,
        "&.Mui-disabled": {
          opacity: "40%",
          color: "#fff",
        },
      }}
    >
      {!loading ? (
        children
      ) : (
        <CircularProgress
          sx={{
            color: "#fff",
          }}
          size="1.5rem"
          className="animate-spin "
        />
      )}
    </Button>
  );
}
