import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { EmployeeUser } from "../../types";
import ResendIcon from "../icons/Resend";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "../../context/toast-context";
import { UserService } from "../../services/user-service";
import { EmployeeFirstLoginRes } from "../../services/dto/response/user-response-dto";

interface ResendValidationProps {
  employee: EmployeeUser | EmployeeFirstLoginRes;
  size?: "default" | "small";
}

export function ResendValidation({
  employee,
  size = "default",
}: ResendValidationProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { showToast } = useToast();
  const { t } = useTranslation();

  const userService = useMemo(() => new UserService(), []);

  const handleResend = async () => {
    setLoading(true);
    await userService.resendVerificationEmail(
      {
        userid: employee.userId.toString(),
      },
      () => {
        showToast(t("employee.verify.resend"), "success");
        setLoading(false);
        setOpen(false);
      },
      (error) => {
        showToast(error, "error");
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        title="Reenviar correo"
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          width: size === "default" ? "2.5rem" : "1.7rem",
          height: size === "default" ? "2.5rem" : "1.7rem",
          padding: size === "small" ? "0.25rem" : "inherit",
        }}
      >
        <ResendIcon
          sx={{
            width: size === "default" ? "1em" : "1.1rem",
            height: size === "default" ? "1em" : "1.1rem",
          }}
        />
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={t("")}
        aria-describedby={t("")}
      >
        <DialogTitle
          sx={{
            padding: "20px 24px",
            fontWeight: 400,
            fontSize: "16px",
            borderRadius: "30px",
            "&.MuiDialogTitle-root": {
              fontFamily: "Montserrat",
            },
          }}
          id="resend-validation-employee"
        >
          ¿Estás seguro de que deseas reenviar el correo de validación a{" "}
          {employee.email}?
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{
              fontFamily: "Montserrat",
              color: "#000",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
            }}
            onClick={() => setOpen(false)}
            color="inherit"
            disabled={loading}
          >
            {t("employee.delete.cancel")}
          </Button>
          <Button
            sx={{
              fontFamily: "Montserrat",
              backgroundColor: "#EF0BB8",
              color: "#fff",
              fontSize: "0.875rem",
              textTransform: "capitalize",
              fontWeight: 300,
              width: "90px",
              "&.Mui-disabled": {
                opacity: "40%",
                color: "#fff",
              },
            }}
            onClick={handleResend}
            disabled={loading}
          >
            {!loading ? (
              <span>Reenviar</span>
            ) : (
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1.5rem"
                className="animate-spin "
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
