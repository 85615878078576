import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DivixLogo from "../assets/img/logos/divixNegro.png";
import { useEffect, useMemo, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { Arrow } from "../components/icons/Arrow";
import { YourTeamItem } from "../components/teams/your-team-item";
import { Group } from "../types";
import { useToast } from "../context/toast-context";
import { GroupService } from "../services/group-service";
import { Loading } from "../components/Loading";
import { BranchService } from "../services/branch-service";
import { EmployeeFirstLoginRes } from "../services/dto/response/user-response-dto";
import { BranchInfo } from "./SetupTeam";

interface TeamState {
  branchId: number;
  branchName: string;
}

export function YourTeam() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [employees, setEmployees] = useState<EmployeeFirstLoginRes[]>([]);
  const [branchesInfo, setBranchesInfo] = useState<BranchInfo | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const groupService = useMemo(() => new GroupService(), []);
  const branchService = useMemo(() => new BranchService(), []);

  const { showToast } = useToast();

  const state = location.state as TeamState | undefined;
  const enabledSameAmount = branchesInfo ? branchesInfo.equalSplitTip : false;
  const handleUpdateEmployees = (updatedEmployees: EmployeeFirstLoginRes[]) => {
    setEmployees(() => [...updatedEmployees]);
  };

  useEffect(() => {
    if (!state || !state.branchId || !state.branchName) {
      navigate("/dashboard");
    }
  }, [state, navigate]);

  const goBack = () => {
    navigate(-1);
  };

  const goNext = () => {
    if (enabledSameAmount) {
      navigate("/dashboard");
    } else {
      navigate("/setup-percentages", {
        state: {
          branchId: state!.branchId,
          branchName: state!.branchName,
        },
      });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchEmployees = async () => {
      setIsLoading(true);

      if (state && state.branchId) {
        try {
          await branchService.getBranchesInformation(
            {
              limit: 100,
              offset: 0,
              branchid: state.branchId.toString(),
            },
            (data) => {
              setBranchesInfo({
                active: data[0].active,
                address: data[0].address,
                branchId: data[0].branchId,
                companyId: data[0].companyId,
                employeeCount: data[0].employeeCount,
                equalSplitTip: data[0].equalSplitTip,
                name: data[0].name,
                phone: data[0].phone,
              });
              setEmployees(data[0].employees);
              setGroups(data[0].groups);
            },
            (error) => showToast(error, "error")
          );
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchEmployees();
    return () => {
      controller.abort();
    };
  }, [showToast, groupService, branchService, state]);

  return (
    <div className="w-full h-screen relative">
      <Link to="/dashboard" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>

      <div className="absolute top-6 right-6">
        <IconButton
          aria-label="Volver"
          title={t("go-back")}
          onClick={goBack}
          color="primary"
        >
          <Arrow />
        </IconButton>
      </div>

      <main className="container mx-auto">
        <div className="flex flex-col text-[#616161] mx-auto justify-center items-center pt-32">
          <h1 className="font-light text-center tracking-wide text-3xl">
            {t("teams.setup.team")}
          </h1>

          {isLoading && <Loading sidebar={false} />}
          {!isLoading && !employees.length && (
            <p className="mt-10 text-[#898989] font-semibold text-xl">
              {t("branches.view.empty")}
            </p>
          )}
          {!isLoading && employees && branchesInfo && (
            <>
              <div className="flex flex-col gap-2 mt-10 justify-center items-center">
                {employees.map((employee) => (
                  <YourTeamItem
                    key={employee.email}
                    employee={employee}
                    onUpdateEmployees={handleUpdateEmployees}
                    branchesInfo={branchesInfo}
                    groups={groups}
                  />
                ))}

                <div className="flex pt-4 gap-1 items-center justify-center">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="#000"
                      opacity="70%"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12 17v-6"
                      stroke="#000"
                      opacity="70%"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="matrix(1 0 0 -1 11 9)"
                      fill="#000"
                      opacity="70%"
                    />
                  </svg>
                  <p className="text-center opacity-70 text-sm font-medium">
                    {t("teams.setup.team-note")}
                  </p>
                </div>
              </div>

              <div className="flex w-full justify-center mt-12 mx-6">
                <div className="w-full max-w-xs">
                  <Button
                    onClick={goNext}
                    type="button"
                    sx={{
                      padding: "1px 8px",
                      width: "100%",
                      background: "#000",
                      borderRadius: "16px",
                      color: "white",
                      textTransform: "none",
                      fontFamily: "Montserrat",
                      fontWeight: 300,
                      "&.Mui-disabled": {
                        opacity: "40%",
                        color: "#fff",
                      },
                    }}
                  >
                    {t("next")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
}
