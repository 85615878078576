import { useTranslation } from "react-i18next";
import Logo from "../assets/img/logos/X.svg";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AcceptTermsModal } from "../components/modals/AcceptTermsModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../context/toast-context";
import { UserService } from "../services/user-service";
import { UnverifiedUser } from "../services/dto/response/user-response-dto";
import { Loading } from "../components/Loading";

export function VerifyManager() {
  const { t } = useTranslation();

  const [manager, setManager] = useState<UnverifiedUser | null>(null);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userService = useMemo(() => new UserService(), []);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const userid = params.get("userid");
  const token = params.get("token");

  const isFormValid =
    password === confirmPassword && password !== "" && isTermsChecked;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsChecked((prev) => !prev);
  };

  const handleAcceptTerms = () => {
    setIsTermsChecked(true);
    setIsOpenModal(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsVerifying(true);
    e.preventDefault();
    if (!isFormValid) {
      setErrors({
        ...errors,
        password: t("employee.verify.errors.password"),
      });
      setIsVerifying(false);
      return;
    }

    await userService.verifyManager(
      { Password: password, Token: token!, UserId: Number(userid!) },
      () => {
        setIsVerifying(false);
        navigate("/account-verified");
      },
      (error) => {
        setIsVerifying(false);
        showToast(error, "error");
      }
    );
  };

  useEffect(() => {
    const fetchManager = async () => {
      setIsLoading(true);
      const controller = new AbortController();
      const { signal } = controller;

      try {
        await userService.getUnverifiedUser(
          {
            token: token!,
            userid: Number(userid!),
          },
          (data) => {
            setManager(data);
          },
          (error) => {
            setErrors({ verified: "Usuario verificado" });
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    if (userid && token) {
      fetchManager();
    }
  }, [token, userid, userService]);

  return (
    <main className="h-screen container mx-auto">
      <div className="flex flex-col mx-auto justify-center items-center pt-12">
        <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
        {!!errors.verified && !isLoading && (
          <div className="mt-20 text-xl text-center font-semibold">
            <p>{errors.verified}</p>
          </div>
        )}
        {!errors.verified && isLoading && <Loading sidebar={false} />}
        {!errors.verified && !isLoading && manager && (
          <>
            <h1 className="font-normal text-center tracking-wide text-5xl pt-14">
              {t("verify-account.welcome")}
            </h1>
            <span className="text-2xl pt-6 text-center font-normal max-w-2xl">
              {t("verify-account.subtitle")}
            </span>

            <form className="mt-12 max-w-xs w-full" onSubmit={handleSubmit}>
              <div className="grid gap-2">
                <TextField
                  error={!!errors.password}
                  helperText={errors.password}
                  label={
                    <div className="flex items-center gap-4">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4 6V4a4 4 0 1 1 8 0v2h2v10H2V6h2Zm2-2a2 2 0 1 1 4 0v2H6V4Zm1 9V9h2v4H7Z"
                          fill="#000"
                        />
                      </svg>
                      {t("change-password.new")}
                    </div>
                  }
                  variant="standard"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                      fontSize: "1rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
                <TextField
                  error={!!errors.password}
                  helperText={errors.password}
                  label={
                    <div className="flex items-center gap-4">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4 6V4a4 4 0 1 1 8 0v2h2v10H2V6h2Zm2-2a2 2 0 1 1 4 0v2H6V4Zm1 9V9h2v4H7Z"
                          fill="#000"
                        />
                      </svg>
                      {t("change-password.repeat")}
                    </div>
                  }
                  variant="standard"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#000",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#838383",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#838383",
                      fontWeight: "300",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
              </div>

              <div className="w-full flex justify-center gap-1 items-center text-center mx-auto mt-8 px-2">
                <Checkbox
                  checked={isTermsChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                  sx={{
                    color: "#000",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#EF0BB8",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                      margin: 0,
                      padding: 0,
                    },
                  }}
                />
                <div className="text-xs font-semibold">
                  Acepto{" "}
                  <span
                    className="hover:underline cursor-pointer"
                    onClick={() => setIsOpenModal(true)}
                  >
                    Términos & Condiciones
                  </span>
                </div>
              </div>

              <div className="w-full mx-auto mt-12">
                <button
                  type="submit"
                  disabled={!isTermsChecked || isVerifying}
                  className={`w-full leading-5 bg-black text-white font-light rounded-3xl py-1 px-4 disabled:opacity-50 ${
                    isTermsChecked
                      ? "hover:bg-black/90 opacity-100"
                      : "opacity-50"
                  } `}
                >
                  {isVerifying ? (
                    <CircularProgress
                      sx={{
                        color: "#fff",
                      }}
                      size="1.5rem"
                      className="animate-spin "
                    />
                  ) : (
                    t("verify-account.submit")
                  )}
                </button>
              </div>
            </form>

            <span className="pt-6 font-medium text-lg">
              {t("verify-account.bye")}
            </span>
          </>
        )}
      </div>
      {isOpenModal && (
        <AcceptTermsModal
          onAccept={handleAcceptTerms}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </main>
  );
}
