import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EditTeam } from "./edit-team";
import { ShareEmployee } from "./share-employee";
import { DeleteEmployee } from "./delete-employee";
import { EmployeeFirstLoginRes } from "../../services/dto/response/user-response-dto";
import { BranchInfo } from "../../screens/SetupTeam";
import { Group } from "../../types";
import { ResendValidation } from "./resend-validation";

interface YourTeamItemProps {
  employee: EmployeeFirstLoginRes;
  onUpdateEmployees: (updatedEmployees: EmployeeFirstLoginRes[]) => void;
  branchesInfo: BranchInfo;
  groups: Group[];
}

export function YourTeamItem({
  employee,
  onUpdateEmployees,
  branchesInfo,
  groups,
}: YourTeamItemProps) {
  const { t } = useTranslation();

  const emtpyGroup = !employee.userGroups || !employee.userGroups.length;

  const URL = process.env.REACT_APP_LANDING_URL;
  const link = `${URL}/?userId=${employee.userId}&branchId=${branchesInfo.branchId}`;

  return (
    <>
      <div>
        <div>
          <div className="flex items-end  gap-4 justify-center">
            <TextField
              type="text"
              label={t("employee.create.fullname")}
              variant="standard"
              value={employee.name}
              sx={{
                "& .MuiInput-root": {
                  color: "#898989",
                  fontFamily: "Montserrat",
                  pointerEvents: "none",
                  "&:before": {
                    borderBottom: "1px solid #898989",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#898989",
                },
                "& .MuiInputLabel-standard": {
                  color: "#898989",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#898989",
                },
              }}
            />
            <TextField
              type="email"
              label={t("employee.create.email")}
              variant="standard"
              value={employee.email}
              sx={{
                "& .MuiInput-root": {
                  color: "#898989",
                  fontFamily: "Montserrat",
                  pointerEvents: "none",
                  "&:before": {
                    borderBottom: "1px solid #898989",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#898989",
                },
                "& .MuiInputLabel-standard": {
                  color: "#898989",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#898989",
                },
              }}
            />

            <div className="px-2 w-[230px] truncate py-1 max-w-52 h-full  rounded-full border bg-transparent border-[#898989] outline-primary opacity-70">
              {!emtpyGroup
                ? employee.userGroups.map((group) => group.name).join(", ")
                : t("same-tip")}
            </div>

            {employee.useQR ? (
              <div className="border border-primary rounded-full p-1">
                <svg
                  fill="#EF0BB8"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 15v2H3v-2Zm-2 6h2v-2H3Zm2-4v2h2v-2Zm4-6H7v2H5v2h4Zm-6 0v2h2v-2Zm6 8H7v2h5v-2h-1v-2H9Zm4-15h-2v2h2Zm-2 7h2V8h-2ZM4 9a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1Zm1-2h2V5H5Zm16-3v8h-2V9h-3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1Zm-2 1h-2v2h2Zm2 11v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-3h-4v-4h2v2h1v-4h2v4h4a1 1 0 0 1 1 1Zm-2 1h-2v2h2Z" />
                </svg>
              </div>
            ) : (
              <div className="rounded-full p-1 w-[30px] " />
            )}

            <div className="inline-flex w-52 items-center justify-center rounded-full border uppercase border-primary text-primary font-medium px-3 mx-2 py-1 text-sm ">
              {employee.isVerified ? t("verified") : t("pending-validation")}
            </div>

            <div className="flex w-[150px] items-center justify-end gap-2">
              {!employee.isVerified && (
                <ResendValidation size="small" employee={employee} />
              )}

              {employee.useQR && <ShareEmployee link={link} />}
              <EditTeam
                employee={employee}
                groups={groups}
                equalSplitTip={branchesInfo.equalSplitTip}
                updateEmployees={onUpdateEmployees}
                branchId={branchesInfo.branchId}
              />
              <DeleteEmployee
                userId={employee.userId}
                updateEmployees={onUpdateEmployees}
                branchId={branchesInfo.branchId}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
