import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RatingService } from "../services/rating-service";
import { useToast } from "./toast-context";

interface UnreadMessagesContextProps {
  unreadCount: number;
  updateCount: (delta: number) => void;
}

const UnreadMessagesContext = createContext<
  UnreadMessagesContextProps | undefined
>(undefined);

const TIMER = 60000; // 1 minuto

export function UnreadMessagesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [unreadCount, setUnreadCount] = useState(0);

  const ratingService = useMemo(() => new RatingService(), []);
  const { showToast } = useToast();

  const updateCount = (delta: number) => {
    setUnreadCount((prev) => {
      const newCount = Math.max(0, prev + delta);
      return newCount;
    });
  };

  const fetchUnreadMessages = useCallback(async () => {
    await ratingService.getUnreadCount(
      null,
      (data) => {
        setUnreadCount(data.unRead);
      },
      (error) => {
        showToast(error, "error");
      }
    );
  }, [ratingService, showToast]);

  useEffect(() => {
    fetchUnreadMessages();

    const intervalId = setInterval(fetchUnreadMessages, TIMER);
    return () => clearInterval(intervalId);
  }, [fetchUnreadMessages]);

  return (
    <UnreadMessagesContext.Provider
      value={{
        unreadCount,
        updateCount,
      }}
    >
      {children}
    </UnreadMessagesContext.Provider>
  );
}

export function useUnreadMessages() {
  const context = useContext(UnreadMessagesContext);
  if (!context) {
    throw new Error("Error, contacte al administrador");
  }
  return context;
}
