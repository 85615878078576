import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

const ResendIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  ({ ...props }, ref) => (
    <SvgIcon
      fill="#EF0BB8"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      ref={ref}
      {...props}
    >
      <path d="M24 14v5c0 2.757-2.243 5-5 5H5c-2.757 0-5-2.243-5-5V8c0-2.757 2.243-5 5-5h4a1 1 0 1 1 0 2H5a2.99 2.99 0 0 0-2.639 1.604l7.518 7.518c1.133 1.133 3.109 1.133 4.242 0l.329-.329a1.001 1.001 0 0 1 1.415 1.414l-.329.329C14.592 16.48 13.337 17 12.001 17s-2.591-.52-3.535-1.464L2 9.071V19c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-5a1 1 0 1 1 2 0ZM22.57.241l-.981.958A5.941 5.941 0 0 0 18 0c-3.309 0-6 2.691-6 6s2.691 6 6 6a6.001 6.001 0 0 0 5.5-3.6 1 1 0 1 0-1.832-.801A4.004 4.004 0 0 1 18 9.999c-2.206 0-4-1.794-4-4s1.794-4 4-4a3.95 3.95 0 0 1 2.119.635l-.992.969c-.528.515-.154 1.397.592 1.397h3.02C23.435 5 24 4.449 24 3.769V.82c0-.729-.902-1.094-1.43-.578Z" />
    </SvgIcon>
  )
);

export default ResendIcon;
