import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditGroupForm } from "../form/edit-group-form";
import { Group } from "../../types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface EditGroupProps {
  updateGroup: (updatedGroup: Group) => void;
  group: Group;
}

export function EditGroup({ updateGroup, group }: EditGroupProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        title={t("teams.group.edit.title")}
        onClick={() => setIsModalOpen(true)}
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          width: "2.5rem",
          height: "2.5rem",
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#EF0BB8">
            <path d="M8.293 3.707 1 11v4h4l7.293-7.293-4-4ZM9.707 2.293l4 4 1.465-1.465a2.829 2.829 0 0 0-4-4L9.707 2.293Z" />
          </g>
        </svg>
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby={t("teams.group.edit.title")}
        aria-describedby={t("teams.group.edit.title")}
      >
        <Box sx={style}>
          <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
            <h1>{t("teams.group.edit.title")}</h1>
          </div>
          <div className="p-8">
            <EditGroupForm
              updateGroup={updateGroup}
              group={group}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}
