import React from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
import {} from "./i18n/i18n";
import { AuthProvider } from "./context/auth-context";
import { RequestInterceptor } from "./config/request-interceptor";
import { ToastProvider } from "./context/toast-context";
import Toast from "./components/Toast";

const App = () => {
  return (
    <ToastProvider>
      <AuthProvider>
        <RequestInterceptor />
        <RouterProvider router={router} />
      </AuthProvider>
      <Toast />
    </ToastProvider>
  );
};

export default App;
