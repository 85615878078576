import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/img/logos/divixNegro.png";
import XLogo from "../../assets/img/logos/X.svg";
import { Monitor } from "../../components/icons/Monitor";
import { Team } from "../../components/icons/Team";
import { Sucursal } from "../../components/icons/Sucursal";
import { Message } from "../../components/icons/Message";
import { Config } from "../../components/icons/Config";
import { useTranslation } from "react-i18next";
import { Group } from "../../components/icons/Group";
import { UserService } from "../../services/user-service";
import { useToast } from "../../context/toast-context";
import { User } from "../../types";
import { SidebarSkeleton } from "../../components/skeletons/sidebar-skeleton";
import { Avatar } from "@mui/material";
import { useAuth } from "../../context/auth-context";
import { UserDropdown } from "./UserDropdown";
import { useUnreadMessages } from "../../context/unread-messages-context";

export function Sidebar() {
  const [expanded, setExpanded] = useState(true);
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const { unreadCount } = useUnreadMessages();
  const { t } = useTranslation();
  const userService = useMemo(() => new UserService(), []);
  const { showToast } = useToast();
  const { logout } = useAuth();

  const [loading, setLoading] = useState(true);

  const isActive = (path: string) => {
    if (path === "/dashboard") {
      return location.pathname === path;
    }

    return location.pathname.startsWith(path);
  };

  const username = user?.name.length ? user.name.split(" ")[0] : user?.name;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1000px)");
    setExpanded(mediaQuery.matches);

    const handleMediaChange = (e: MediaQueryListEvent) => {
      setExpanded(e.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      await userService.getUser(
        null,
        (data) => {
          setUser(data);
          setLoading(false);
        },
        (error) => {
          showToast(error, "error");
          setLoading(false);
        }
      );
    };

    fetchUser();
  }, [userService, showToast]);

  if (loading) return <SidebarSkeleton />;

  return (
    <aside
      className={`z-50 hidden max-h-screen gap-y-5 overflow-x-hidden rounded-3xl p-1 sm:p-4 bg-white transition-all duration-300 ease-in-out sm:flex sm:flex-col ${
        expanded ? "w-52" : "w-10 min-w-fit"
      }`}
    >
      {/* Header del sidebar */}
      <div
        className={
          expanded
            ? "flex h-6 items-center justify-between"
            : "flex flex-col items-center justify-center gap-4"
        }
      >
        {expanded ? (
          <img className="h-full" src={Logo} alt="Divix Logo" />
        ) : (
          <img className="h-6" src={XLogo} alt="Divix Logo" />
        )}
        <button onClick={() => setExpanded((curr) => !curr)}>
          {expanded ? (
            <div className="rotate-180 transform transition-transform ease-linear duration-75">
              <svg
                fill="#ececec"
                height="16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330 330"
                xmlSpace="preserve"
              >
                <path d="m250.606 154.389-150-149.996c-5.857-5.858-15.355-5.858-21.213.001-5.857 5.858-5.857 15.355.001 21.213l139.393 139.39L79.393 304.394c-5.857 5.858-5.857 15.355.001 21.213C82.322 328.536 86.161 330 90 330s7.678-1.464 10.607-4.394l149.999-150.004a14.996 14.996 0 0 0 0-21.213z" />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                fill="#ececec"
                height="16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330 330"
                xmlSpace="preserve"
              >
                <path d="m250.606 154.389-150-149.996c-5.857-5.858-15.355-5.858-21.213.001-5.857 5.858-5.857 15.355.001 21.213l139.393 139.39L79.393 304.394c-5.857 5.858-5.857 15.355.001 21.213C82.322 328.536 86.161 330 90 330s7.678-1.464 10.607-4.394l149.999-150.004a14.996 14.996 0 0 0 0-21.213z" />
              </svg>
            </div>
          )}
        </button>
      </div>

      {expanded && (
        <div className="text-wrap mt-4">
          <h2 className="text-2xl font-light text-[#838382] block">
            {t("sidebar.hi")}
          </h2>
          <h2 className="text-2xl font-light text-[#838382] block">
            {username}!
          </h2>
        </div>
      )}

      {/* Navegación */}
      <div className="flex flex-col justify-center mt-6 flex-1">
        <nav className="flex h-full flex-col gap-5">
          <Link
            className={`inline-flex items-center whitespace-nowrap tracking-wider text-xs font-light transition-colors h-10 rounded-full ${
              isActive("/dashboard")
                ? "text-white bg-black"
                : "text-[#83838b] bg-transparent hover:bg-black/10"
            } ${expanded ? "sm:px-5 justify-start" : "justify-center px-1"}`}
            to="/dashboard"
          >
            <div className={`${expanded && "mr-2"}`}>
              {isActive("/dashboard") ? (
                <Monitor stroke="#ececec" />
              ) : (
                <Monitor stroke="#83838b" />
              )}
            </div>
            {expanded && <span>Dashboard</span>}
          </Link>
          <Link
            className={`inline-flex items-center whitespace-nowrap tracking-wider text-xs font-light transition-colors h-10 rounded-full  ${
              isActive("/dashboard/teams")
                ? "text-white bg-black"
                : "text-[#83838b] bg-transparent hover:bg-black/10"
            } ${expanded ? "sm:px-5 justify-start" : "justify-center px-1"}`}
            to="/dashboard/teams"
          >
            <div className={`${expanded && "mr-2"}`}>
              {isActive("/dashboard/teams") ? (
                <Team stroke="#ececec" />
              ) : (
                <Team stroke="#83838b" />
              )}
            </div>
            {expanded && t("sidebar.team")}
          </Link>
          <Link
            className={`inline-flex items-center whitespace-nowrap tracking-wider text-xs font-light transition-colors h-10 rounded-full ${
              isActive("/dashboard/branches")
                ? "text-white bg-black"
                : "text-[#83838b] bg-transparent hover:bg-black/10"
            } ${expanded ? "sm:px-5 justify-start" : "justify-center px-1"}`}
            to="/dashboard/branches"
          >
            <div className={`${expanded && "mr-2"}`}>
              {isActive("/dashboard/branches") ? (
                <Sucursal fill="#ececec" />
              ) : (
                <Sucursal fill="#83838b" />
              )}
            </div>
            {expanded && t("sidebar.branches")}
          </Link>
          <Link
            className={`inline-flex items-center whitespace-nowrap tracking-wider text-xs font-light transition-colors h-10 rounded-full ${
              isActive("/dashboard/groups")
                ? "text-white bg-black"
                : "text-[#83838b] bg-transparent hover:bg-black/10"
            } ${expanded ? "sm:px-5 justify-start" : "justify-center px-1"}`}
            to="/dashboard/groups"
          >
            <div className={`${expanded && "mr-2"}`}>
              {isActive("/dashboard/groups") ? (
                <Group fill="#ececec" />
              ) : (
                <Group fill="#83838b" />
              )}
            </div>
            {expanded && t("sidebar.groups")}
          </Link>
          <Link
            className={`inline-flex items-center whitespace-nowrap tracking-wider text-xs font-light transition-colors h-10 rounded-full ${
              isActive("/dashboard/messages")
                ? "text-white bg-black"
                : "text-[#83838b] bg-transparent hover:bg-black/10"
            } ${expanded ? "sm:px-5 justify-start" : "justify-center px-1"}`}
            to="/dashboard/messages"
          >
            <div className={`${expanded && "mr-2"} relative`}>
              {isActive("/dashboard/messages") ? (
                <Message stroke="#ececec" />
              ) : (
                <Message stroke="#71717a" />
              )}
              {unreadCount > 0 && (
                <span className="absolute -top-1 -left-1 bg-red text-white text-[11px] rounded-full w-4 h-4 flex items-center justify-center">
                  {unreadCount > 99 ? "99" : unreadCount}
                </span>
              )}
            </div>
            {expanded && t("sidebar.messages")}
          </Link>
          <Link
            className={`inline-flex items-center whitespace-nowrap tracking-wider text-xs font-light transition-colors h-10 rounded-full ${
              isActive("/dashboard/settings")
                ? "text-white bg-black"
                : "text-[#83838b] bg-transparent hover:bg-black/10"
            } ${expanded ? "sm:px-5 justify-start" : "justify-center px-1"}`}
            to="/dashboard/settings"
          >
            <div className={`${expanded && "mr-2"}`}>
              {isActive("/dashboard/settings") ? (
                <Config fill="#ececec" />
              ) : (
                <Config fill="#83838b" />
              )}
            </div>
            {expanded && t("sidebar.settings")}
          </Link>
        </nav>
      </div>

      {/* Usuario */}

      <div role="none" className="shrink-0 bg-gray-light h-[1px] w-full" />
      <div className="flex w-full items-center gap-2">
        {expanded ? (
          <>
            <div className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
              <Avatar
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                src="https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
                alt="Avatar Usuario"
              />
            </div>

            <div className="flex flex-col items-start justify-center">
              <p className="font-semibold text-sm">{user?.name}</p>
              <button onClick={logout} className="text-xs hover:underline">
                {t("logout")}
              </button>
            </div>
          </>
        ) : (
          <UserDropdown />
        )}
      </div>
    </aside>
  );
}
