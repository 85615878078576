import { useCallback, useRef } from "react";

interface useInfinityScrollProps {
  loadMore: () => void;
  hasMore: boolean;
  loading: boolean;
}

export function useInfinityScroll({
  loadMore,
  hasMore,
  loading,
}: useInfinityScrollProps) {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            loadMore();
          }
        },
        {
          root: null,
          rootMargin: "20px",
          threshold: 0.1,
        }
      );

      if (node) observer.current.observe(node);
    },
    [loadMore, hasMore]
  );

  return lastElementRef;
}
