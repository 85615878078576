import { Settings } from "../types";
import BaseService from "./base-service";
import { RequestDto } from "./dto/request/request-dto";

export class CompanyService extends BaseService {
  private resource = "/api/company";

  async getSettings(
    params: null,
    onSuccess: (data: Settings[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/configuration", params);
    await this.doGet<Settings[]>(reqParams, onSuccess, onError);
  }
}
