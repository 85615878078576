import BaseService from "./base-service";
import {
  GetRatingsReq,
  UpdateRatingReq,
} from "./dto/request/ratings-request-dto";
import { RequestDto } from "./dto/request/request-dto";
import {
  GetRatingsRes,
  GetUnreadCount,
} from "./dto/response/ratings-response-dto";

export class RatingService extends BaseService {
  private resource = "/api/ratings";

  async getRatings(
    params: GetRatingsReq,
    onSuccess: (data: GetRatingsRes) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/", params);
    await this.doGet<GetRatingsRes>(reqParams, onSuccess, onError);
  }

  // marcamos como leído/no leído
  async updateRating(
    params: UpdateRatingReq,
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/", params);
    await this.doPatch(reqParams, onSuccess, onError);
  }

  async getUnreadCount(
    params: null,
    onSuccess: (data: GetUnreadCount) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/unread", params);
    await this.doGet<GetUnreadCount>(reqParams, onSuccess, onError);
  }
}
