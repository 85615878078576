import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface WarningModalProps {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
}

export function WarningModal({
  message,
  onClose,
  open,
  title,
}: WarningModalProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        // Evitamos que al hacer click fuera del modal se cierre, obligando al usuario a clickear "Aceptar"
        if (reason === "backdropClick") return;
        onClose();
      }}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitle
        sx={{
          padding: "20px 24px",
          fontWeight: 400,
          fontSize: "16px",
          borderRadius: "30px",
          "&.MuiDialogTitle-root": {
            fontFamily: "Montserrat",
          },
        }}
        id="warning-dialog-title"
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: "20px",
            fontFamily: "Montserrat",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          fontFamily: "Montserrat",
        }}
      >
        {message}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontFamily: "Montserrat",
            backgroundColor: "#EF0BB8",
            color: "#fff",
            fontSize: "0.875rem",
            textTransform: "capitalize",
            fontWeight: 300,
            width: "90px",
          }}
          onClick={onClose}
          color="primary"
        >
          {t("accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
