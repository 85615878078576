import axios from "axios";
import { RequestDto } from "./dto/request/request-dto";
import { ResponseDto } from "./dto/response/response-dto";

class BaseService {
  private domainEndpoint: string = process.env.REACT_APP_BASE_URL ?? "";

  private getUrlEndpoint(resource: string): string {
    return this.domainEndpoint + resource;
  }

  protected async doGet<T>(
    dto: RequestDto,
    postRecover: (data: T) => void,
    errorRecover: (errorMessage: string) => void
  ): Promise<void> {
    try {
      const { data, status } = await axios.get<T>(
        this.getUrlEndpoint(dto.resource),
        { params: dto.data, validateStatus: (status) => status < 500 }
      );
      const response = this.GetResponseData<T>(data, status);

      if (response.errorCode !== 0) {
        errorRecover(response.errorMessage || "Hubo un error inesperado.");
      } else {
        postRecover(response.data);
      }
    } catch (error) {
      errorRecover("Hubo un error inesperado, inténtelo más tarde.");
    }
  }

  protected async doPost<T>(
    dto: RequestDto,
    postRecover: (data: T) => void,
    errorRecover: (errorMessage: string) => void
  ): Promise<void> {
    try {
      const { data, status } = await axios.post<T>(
        this.getUrlEndpoint(dto.resource),
        dto.data,
        {
          headers: dto.headers,
          validateStatus: (status) => status < 500,
        }
      );
      const response = this.GetResponseData<T>(data, status);

      if (response.errorCode !== 0) {
        errorRecover(response.errorMessage || "Hubo un error inesperado.");
      } else {
        postRecover(response.data);
      }
    } catch (error) {
      errorRecover("Hubo un error inesperado.");
    }
  }

  protected async doDelete<T>(
    dto: RequestDto,
    postRecover: (data: T) => void,
    errorRecover: (errorMessage: string) => void
  ) {
    try {
      const { data, status } = await axios.delete<T>(
        this.getUrlEndpoint(dto.resource),
        {
          data: dto.data,
          validateStatus: (status) => status < 500,
        }
      );
      const response = this.GetResponseData<T>(data, status);

      if (response.errorCode !== 0) {
        errorRecover(response.errorMessage || "Hubo un error inesperado.");
      } else {
        postRecover(response.data);
      }
    } catch (error) {
      errorRecover("Hubo un error inesperado, inténtelo más tarde.");
    }
  }

  protected async doPatch<T>(
    dto: RequestDto,
    postRecover: (data: T) => void,
    errorRecover: (errorMessage: string) => void
  ): Promise<void> {
    try {
      const { data, status } = await axios.patch<T>(
        this.getUrlEndpoint(dto.resource),
        dto.data,
        {
          headers: dto.headers,
          validateStatus: (status) => status < 500,
        }
      );
      const response = this.GetResponseData<T>(data, status);

      if (response.errorCode !== 0) {
        errorRecover(response.errorMessage || "Hubo un error inesperado.");
      } else {
        postRecover(response.data);
      }
    } catch (error) {
      errorRecover("Hubo un error inesperado.");
    }
  }

  private GetResponseData<T>(data: any, status: number): ResponseDto<T> {
    const response = new ResponseDto<T>();

    response.code = status;
    response.errorCode = data.ErrorCode;
    response.errorMessage = data.ErrorMessage;
    response.data = data.Data;

    return response;
  }
}

export default BaseService;
