import React, { useMemo, useState } from "react";
import { EmployeeFirstLoginRes } from "../../../services/dto/response/user-response-dto";
import { Group, groupTypes } from "../../../types";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonForm } from "../button-form";
import { EditEmployeeFirstLogin } from "../../../services/dto/request/user-request-dto";
import { UserService } from "../../../services/user-service";
import { useToast } from "../../../context/toast-context";
import { BranchService } from "../../../services/branch-service";

interface EditEmployeeFormProps {
  employee: EmployeeFirstLoginRes;
  groups: Group[];
  equalSplitTip: boolean;
  updateEmployees: (updatedEmployees: any) => void;
  branchId: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAll?: boolean;
}

export function EditEmployeeForm({
  employee,
  groups,
  updateEmployees,
  equalSplitTip,
  branchId,
  setIsModalOpen,
  fetchAll = false,
}: EditEmployeeFormProps) {
  const { t } = useTranslation();

  const [editedEmployee, setEditedEmployee] = useState({
    name: employee.name ?? "",
    email: employee.email ?? "",
    groupsIds:
      employee.userGroups.length && employee.userGroups[0].id !== null
        ? employee.userGroups.map((group) => group.id)
        : [],
    hasQR: false,
    groupTypes:
      employee.groupsTypes.length && employee.groupsTypes[0].id !== null
        ? employee.groupsTypes.map((group) => group.typeId)
        : [],
  });

  const [error, setError] = useState<string>("");
  const [loading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const userService = useMemo(() => new UserService(), []);
  const branchService = useMemo(() => new BranchService(), []);

  const handleGroupChange = (value: number[]) => {
    setEditedEmployee((prev) => ({
      ...prev,
      groupsIds: value,
    }));
    setError("");
  };

  const qrGroupId = groups.find((group) => group.name === "QR")?.groupId;

  const handleGroupTypeChange = (value: number[]) => {
    setEditedEmployee((prev) => ({
      ...prev,
      groupTypes: value,
      hasQR: value.includes(2),
      groupsIds: value.includes(1) ? [...prev.groupsIds] : [],
    }));
    setError("");
  };

  const handleInputChange = (
    field: keyof typeof editedEmployee,
    value: string | null
  ) => {
    setEditedEmployee({
      ...editedEmployee,
      [field]: value === "-1" ? null : value,
    });
    setError("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const params: EditEmployeeFirstLogin = {
      Email: editedEmployee.email,
      Groups: editedEmployee.groupsIds.filter((id) => id !== null),
      Name: editedEmployee.name,
      UseQR:
        editedEmployee.hasQR ||
        (!equalSplitTip && editedEmployee.groupTypes.includes(2)),
      UseGroupTypeQr: editedEmployee.groupTypes.includes(2),
      UserId: employee.userId,
      BranchId: branchId,
    };

    await userService.editEmployeeFirstLogin(
      params,
      async () => {
        if (fetchAll) {
          await userService.getEmployees(
            {
              limit: 100,
              offset: 0,
            },
            (data) => {
              updateEmployees(data.users);
            },
            (error) => showToast(error, "error")
          );
        } else {
          await branchService.getBranchesInformation(
            {
              limit: 100,
              offset: 0,
              branchid: branchId.toString(),
            },
            (data) => {
              updateEmployees(data[0].employees);
            },
            (error) => showToast(error, "error")
          );
        }

        showToast(t("employee.edit.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );

    setIsLoading(false);
    setIsModalOpen(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex justify-center items-center px-6 gap-4">
        <div className="flex items-center w-full justify-between">
          <TextField
            type="text"
            fullWidth
            label={t("employee.create.fullname")}
            variant="standard"
            required
            value={editedEmployee.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
        </div>

        <div className="flex items-center w-full justify-between">
          <TextField
            fullWidth
            type="email"
            label={t("employee.create.email")}
            disabled={employee.isVerified}
            required
            variant="standard"
            value={editedEmployee.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
        </div>

        {!equalSplitTip && (
          <>
            <div className="flex mt-6 items-center w-full max-w-[200px] justify-between">
              <Select
                sx={{
                  paddingX: 2,
                  paddingY: 1,
                  margin: 0,
                  fontStyle: "normal",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                  height: "100%",
                  width: "200px",
                  minWidth: "100%",
                  maxWidth: "230px",
                  borderRadius: "9999px",
                  border: "1px solid",
                  borderColor: "#dfdfdf",
                  backgroundColor: "transparent",
                  outline: "none",
                  "&:focus": {
                    outlineColor: "primary.main",
                  },
                  "& .MuiSelect-select": {
                    padding: 0,
                    display: "block",
                    minWidth: 0,
                  },
                  "&.MuiInputBase-root.MuiOutlinedInput-root": {
                    padding: "4px 8px",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                    {
                      WebkitTextFillColor: "#dfdfdf",
                      opacity: "70%",
                    },

                  fontWeight: "400",
                }}
                value={editedEmployee.groupTypes}
                required
                multiple
                input={<OutlinedInput />}
                displayEmpty
                onChange={(e) =>
                  handleGroupTypeChange(e.target.value as number[])
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className="block truncate ">
                        {t("select.default")}
                      </span>
                    );
                  }
                  const selectedTypeNames = selected
                    .map((typeId) => {
                      const type = groupTypes.find(
                        (group) => group.groupTypeId === typeId
                      );
                      return type ? type.name : "";
                    })
                    .filter((name) => name !== "");

                  return selectedTypeNames.join(", ");
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Montserrat",
                  }}
                  disabled
                  value=""
                >
                  <span>{t("select.default")}</span>
                </MenuItem>
                {groupTypes.map((type) => (
                  <MenuItem
                    sx={{
                      fontFamily: "Montserrat",
                    }}
                    key={type.groupTypeId}
                    value={type.groupTypeId}
                  >
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex mt-6 items-center w-full max-w-[200px] justify-between">
              <Select
                sx={{
                  paddingX: 2,
                  paddingY: 1,
                  margin: 0,
                  fontStyle: "normal",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                  height: "100%",
                  width: "230px",
                  minWidth: "100%",
                  maxWidth: "230px",
                  borderRadius: "9999px",
                  border: "1px solid",
                  borderColor: "#838383",
                  backgroundColor: "transparent",
                  outline: "none",
                  "&:focus": {
                    outlineColor: "primary.main",
                  },
                  "& .MuiSelect-select": {
                    padding: 0,
                    display: "block",
                    minWidth: 0,
                  },
                  "&.MuiInputBase-root.MuiOutlinedInput-root": {
                    padding: "4px 8px",
                  },

                  fontWeight: "400",
                }}
                required
                multiple
                input={<OutlinedInput />}
                disabled={!editedEmployee.groupTypes.includes(1)}
                value={editedEmployee.groupsIds.filter(
                  (group) => group !== qrGroupId
                )}
                displayEmpty
                onChange={(e) => handleGroupChange(e.target.value as number[])}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className="block truncate ">
                        {t("select.group")}
                      </span>
                    );
                  }

                  return selected
                    .map(
                      (groupId) =>
                        groups.find((group) => group.groupId === groupId)?.name
                    )
                    .filter((name) => name)
                    .join(", ");
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Montserrat",
                  }}
                  disabled
                  value=""
                >
                  <span>{t("employee.create.group")}</span>
                </MenuItem>
                {groups
                  .filter((group) => group.name !== "QR")
                  .map((group) => (
                    <MenuItem
                      sx={{
                        fontFamily: "Montserrat",
                      }}
                      key={group.name}
                      value={group.groupId}
                    >
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </>
        )}
        <div className="flex mt-6 items-center">
          <FormControlLabel
            sx={{
              "& .MuiTypography-root": {
                color: "#898989",
                paddingLeft: "2px",
                fontFamily: "Montserrat",
              },
            }}
            control={
              <Checkbox
                sx={{
                  color: "#898989",
                  padding: 0,
                  "&.Mui-checked": {
                    color: "#EF0BB8",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 24,
                    margin: 0,
                    padding: 0,
                  },
                }}
                onChange={(e) => {
                  setError("");
                  if (!editedEmployee.groupTypes.includes(2)) {
                    setEditedEmployee((prev) => ({
                      ...prev,
                      hasQR: e.target.checked,
                    }));
                  }
                }}
                checked={
                  editedEmployee.hasQR ||
                  (!equalSplitTip && editedEmployee.groupTypes.includes(2))
                }
              />
            }
            label="QR"
          />
        </div>
      </div>

      {error && <div className="mt-4 text-red text-center">{error}</div>}
      <div className="flex justify-center mt-12 mx-6">
        <div className="w-full max-w-sm">
          <ButtonForm loading={loading}>{t("employee.edit.submit")}</ButtonForm>
        </div>
      </div>
    </form>
  );
}
