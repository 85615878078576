import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Branch } from "../types";
import { BranchSelector } from "../components/branches/branch-selector";

export function Groups() {
  const [selectedBranch, setSelectedBranch] = useState<
    Branch["branchId"] | null
  >(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBranchSelect = (branchId: number) => {
    setSelectedBranch(branchId);
  };

  const handleConfirm = () => {
    if (selectedBranch) {
      navigate(`/dashboard/groups/${selectedBranch}`);
    }
  };

  return (
    <main className="h-full flex flex-col rounded-3xl bg-[#a0abba] p-6 overflow-hidden">
      <div className="flex bg-[#2d2f32]  items-center justify-center h-full overflow-y-auto custom-scrollbar rounded-2xl p-4">
        <div className=" bg-white rounded-lg max-w-lg justify-center items-center">
          <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2 rounded-t-lg">
            <h2>{t("groups.select.title")}</h2>
          </div>
          <div className="p-8 max-w-sm mx-auto">
            <BranchSelector
              onBranchSelect={handleBranchSelect}
              selectedBranch={selectedBranch}
            />
            <div className="flex justify-center mt-12 mx-6">
              <Button
                onClick={handleConfirm}
                disabled={!selectedBranch}
                sx={{
                  padding: "4px 8px",
                  width: "100%",
                  background: "#EF0BB8",
                  borderRadius: "16px",
                  color: "white",
                  textTransform: "none",
                  fontFamily: "Montserrat",
                  fontWeight: 300,
                  "&.Mui-disabled": {
                    opacity: "40%",
                    color: "#fff",
                  },
                }}
              >
                {t("groups.select.submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
