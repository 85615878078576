import { TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Group } from "../../types";
import { GroupService } from "../../services/group-service";
import { useToast } from "../../context/toast-context";
import { ButtonForm } from "./button-form";

interface EditGroupFormProps {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  group: Group;
  updateGroup: (group: Group) => void;
}

export function EditGroupForm({
  group,
  updateGroup,
  setIsModalOpen,
}: EditGroupFormProps) {
  const [groupName, setGroupName] = useState(group.name);
  const [errors, setErrors] = useState({
    groupName: "",
  });
  const [loading, setLoading] = useState(false);

  const groupService = useMemo(() => new GroupService(), []);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const validate = () => {
    let data = { groupName: "" };
    let isValid = true;

    if (!groupName || groupName.length < 3) {
      data.groupName = t("teams.group.edit.errors.name");
      isValid = false;
    }

    setErrors(data);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    if (!validate()) {
      setLoading(false);
      return;
    }
    await groupService.editGroup(
      {
        GroupId: group.groupId,
        Name: groupName,
      },
      (data) => {
        updateGroup({
          ...data[0],
          employeeCount: group.employeeCount,
        });
        showToast(t("teams.group.edit.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );

    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid px-6 gap-4">
        <TextField
          type="text"
          label={t("teams.group.edit.name")}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          error={!!errors.groupName}
          helperText={errors.groupName}
          variant="standard"
          sx={{
            "& .MuiInput-root": {
              color: "#000",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #838383",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#7e7e7e",
            },
            "& .MuiInputLabel-standard": {
              color: "#000",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#838383",
            },
          }}
        />
      </div>

      <div className="flex justify-center mt-12 mx-6">
        <ButtonForm loading={loading}>
          {t("teams.group.edit.submit")}
        </ButtonForm>
      </div>
    </form>
  );
}
