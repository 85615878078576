import { useTranslation } from "react-i18next";

interface MessageActionProps {
  hasUnread: boolean;
  onMarkAsRead: () => void;
  onMarkAsUnread: () => void;
}

export function MessageActions({
  hasUnread,
  onMarkAsRead,
  onMarkAsUnread,
}: MessageActionProps) {
  const { t } = useTranslation();

  return (
    <button
      onClick={hasUnread ? onMarkAsRead : onMarkAsUnread}
      className="text-sm p-2 rounded-xl border text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-all duration-100 ease-in"
    >
      {hasUnread ? t("messages.read") : t("messages.unread")}
    </button>
  );
}
