export const getLastWeek = () => {
  const today = new Date();
  const lastWeek = new Date();

  lastWeek.setDate(today.getDate() - 7);
  return {
    startDate: lastWeek.toISOString().split("T")[0],
    endDate: today.toISOString().split("T")[0],
  };
};
