import { Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DivixLogo from "../assets/img/logos/divixBlanco.png";
import { useEffect, useMemo, useState } from "react";
import { Group } from "../types";
import { useToast } from "../context/toast-context";
import { SetupTeamForm } from "../components/form/first-login/setup-team-form";
import { Loading } from "../components/Loading";
import { GroupService } from "../services/group-service";
import { BranchService } from "../services/branch-service";
import { EmployeeFirstLoginRes } from "../services/dto/response/user-response-dto";
import { Arrow } from "../components/icons/Arrow";

interface BranchState {
  branchId: number;
  branchName: string;
}

export interface BranchInfo {
  active: boolean;
  address: string;
  branchId: number;
  companyId: number;
  employeeCount: string;
  equalSplitTip: boolean;
  name: string;
  phone: string;
}

export function SetupTeam() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [employees, setEmployees] = useState<EmployeeFirstLoginRes[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [branchesInfo, setBranchesInfo] = useState<BranchInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const groupService = useMemo(() => new GroupService(), []);
  const branchesService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();

  const state = location.state as BranchState | undefined;

  useEffect(() => {
    if (!state || !state.branchId || !state.branchName) {
      navigate("/dashboard");
    }
  }, [state, navigate]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchEmployees = async () => {
      setIsLoading(true);

      if (state && state.branchId) {
        try {
          await branchesService.getBranchesInformation(
            {
              limit: 100,
              offset: 0,
              branchid: state.branchId.toString(),
            },
            (data) => {
              setBranchesInfo({
                active: data[0].active,
                address: data[0].address,
                branchId: data[0].branchId,
                companyId: data[0].companyId,
                employeeCount: data[0].employeeCount,
                equalSplitTip: data[0].equalSplitTip,
                name: data[0].name,
                phone: data[0].phone,
              });
              setEmployees(data[0].employees);
              setGroups(data[0].groups);
            },
            (error) => showToast(error, "error")
          );
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchEmployees();
    return () => {
      controller.abort();
    };
  }, [showToast, groupService, state, branchesService]);

  return (
    <div className="w-full h-screen relative bg-dark-gradient-to-b">
      <Link to="/dashboard" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>
      <div className="absolute top-6 right-6">
        <Button
          type="button"
          onClick={() => navigate("/dashboard")}
          sx={{
            padding: "2px 8px",
            width: "12rem",
            background: "#EF0BB8",
            borderRadius: "16px",
            color: "white",
            textTransform: "none",
            fontFamily: "Montserrat",
            marginLeft: "10px",
            fontWeight: 300,
            "&.Mui-disabled": {
              opacity: "40%",
              color: "#fff",
            },
          }}
        >
          {t("skip")}
        </Button>

        <IconButton
          aria-label="Volver"
          title={t("next")}
          color="primary"
          onClick={() =>
            navigate("/your-team", {
              state: {
                branchId: state?.branchId,
                branchName: state?.branchName,
              },
            })
          }
        >
          <Arrow direction="right" />
        </IconButton>
      </div>
      {state && branchesInfo && (
        <main className="container mx-auto">
          <div className="flex flex-col text-white mx-auto justify-center items-center pt-32">
            <h1 className="font-light text-center tracking-wide text-3xl">
              {t("teams.setup.title")}
            </h1>
            <h2 className="pt-6 text-[#dfdfdf] text-xl">
              {t("teams.setup.subtitle")}{" "}
              <span className="font-semibold">{state.branchName}</span>
            </h2>

            <div className="flex flex-col mt-10 justify-center items-center">
              {isLoading && <Loading sidebar={false} />}
              {!isLoading && (
                <SetupTeamForm
                  branchName={state.branchName}
                  branchNumberId={state.branchId}
                  branchesInfo={branchesInfo}
                  employees={employees}
                  initialGroups={groups}
                />
              )}
            </div>
          </div>
        </main>
      )}
    </div>
  );
}
