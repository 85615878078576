import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const InfoIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  ({ ...props }, ref) => (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="#000"
        opacity="70%"
        strokeWidth="1.5"
      />
      <path
        d="M12 17v-6"
        stroke="#000"
        opacity="70%"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(1 0 0 -1 11 9)"
        fill="#000"
        opacity="70%"
      />
    </SvgIcon>
  )
);

export default InfoIcon;
