import { Avatar, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../context/auth-context";

export function UserDropdown() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="relative">
      <button
        id="user-dropdown"
        aria-controls={open ? "user-dropdown" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full"
      >
        <Avatar
          sx={{
            width: "100%",
            height: "100%",
          }}
          src="https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
          alt="Avatar Usuario"
        />
      </button>
      <Menu
        id="user-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-dropdown",
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              ml: 5,
              mb: 3,
            },
          },
        }}
      >
        <MenuItem onClick={() => logout()}>Cerrar sesión</MenuItem>
      </Menu>
    </div>
  );
}
