import { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { setCanvasPreview } from "../utils/set-canvas-preview";
import { useTranslation } from "react-i18next";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

interface ImageCropperProps {
  onCropChange: (file: File | null) => void;
  employeeName: string;
}
const MAX_SIZE_BYTES = 1 * 1024 * 1024; // 1MB

export function ImageCropper({
  onCropChange,
  employeeName,
}: ImageCropperProps) {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [crop, setCrop] = useState<Crop | null>(null);
  const [error, setError] = useState<string>("");

  const { t } = useTranslation();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > MAX_SIZE_BYTES) {
      setError(t("images.update-avatar.errors.size"));
      return;
    }

    // Leemos el archivo para obtener la URL
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      // Verificamos tamaño mínimo
      imageElement.addEventListener("load", (e: Event) => {
        if (error) setError("");
        const target = e.target as HTMLImageElement;
        const { naturalWidth, naturalHeight } = target;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError(t("images.update-avatar.errors.dimensions"));
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  // Calcula el recorte inicial basado en el tamaño mínimo y centra el recorte en la imagen
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  // Generamos el nuevo recorte con un canvas
  const generateCroppedFile = () => {
    if (imgRef.current && previewCanvasRef.current && crop) {
      const pixelCrop = convertToPixelCrop(
        crop as Crop,
        imgRef.current.width,
        imgRef.current.height
      ) as PixelCrop;

      setCanvasPreview(imgRef.current, previewCanvasRef.current, pixelCrop);

      previewCanvasRef.current.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], `${employeeName}.jpeg`, {
              type: "image/jpeg",
            });
            onCropChange(file);
          }
        },
        "image/jpeg",
        1
      );
    }
  };

  return (
    <>
      <label className="block mb-3 w-full mx-auto">
        <span className="sr-only">{t("images.update-avatar.label")}</span>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          className="block w-full text-sm text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-[#2d2f32] file:text-white hover:file:bg-[#2d2f32]/90"
        />
      </label>
      {error && <p className="text-red text-xs">{error}</p>}
      {imgSrc && (
        <div className="flex flex-col items-center">
          <ReactCrop
            crop={crop as Crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
              generateCroppedFile();
            }}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "70vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </>
  );
}
