import { useEffect, useMemo, useState } from "react";
import { FormSettings } from "../components/settings/form-settings";
import { UserService } from "../services/user-service";
import { useToast } from "../context/toast-context";
import { useTranslation } from "react-i18next";
import { Settings as SettingsType } from "../types";
import { CompanyService } from "../services/company-service";
import { Loading } from "../components/Loading";

export function Settings() {
  const [config, setConfig] = useState<SettingsType | null>(null);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);
  const [countdown, setCountdown] = useState(0);

  const { showToast } = useToast();
  const { t } = useTranslation();

  const userService = useMemo(() => new UserService(), []);
  const companyService = useMemo(() => new CompanyService(), []);

  const sendEmail = async () => {
    if (config && config.userEmail) {
      setLoading(true);
      await userService.sendRecoverEmail(
        {
          email: config.userEmail,
        },
        () => {
          showToast(t("forgot-password.success"), "success");
          setCountdown(30);
          setLoading(false);
        },
        (error) => {
          showToast(error, "error");
          setLoading(false);
          setCountdown(30);
        }
      );
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    const fetchSettings = async () => {
      setPending(true);
      await companyService.getSettings(
        null,
        (data) => {
          setConfig(data[0]);
        },
        (error) => {
          showToast(error, "error");
        }
      );
      setPending(false);
    };

    fetchSettings();
  }, [companyService, showToast]);

  return (
    <main className="h-full flex flex-col rounded-3xl bg-white p-6 overflow-hidden">
      {pending && <Loading />}
      {config && !pending && (
        <div className="max-w-2xl w-full mx-auto">
          <FormSettings settings={config} />

          <div className="flex flex-col gap-3 items-center justify-center mt-10">
            <button
              disabled={loading || countdown > 0}
              type="button"
              onClick={sendEmail}
              className="mx-auto leading-5 bg-black hover:bg-black/90 text-white font-light rounded-3xl py-1 px-24 disabled:opacity-50 disabled:pointer-events-none"
            >
              {t("settings.password")}
            </button>
            {countdown > 0 && (
              <p className="text-center text-sm text-gray-500">
                {t("forgot-password.resend-message")} {countdown}{" "}
                {t("forgot-password.seconds")}
              </p>
            )}
          </div>
        </div>
      )}
    </main>
  );
}
