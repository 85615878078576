import { Box, Button, Modal } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Group } from "../../types";
import { SetPercentagesForm } from "../groups/set-percentages-form";
import { useToast } from "../../context/toast-context";
import { GroupService } from "../../services/group-service";
import { Loading } from "../Loading";
import { PercentIcon } from "../icons/Percent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface EditPercentProps {
  branchId: number;
  equalSplitTip: boolean;
}

// Este componente se utiliza desde la pantalla sucursal
export function EditPercent({ branchId, equalSplitTip }: EditPercentProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const { showToast } = useToast();

  const groupService = useMemo(() => new GroupService(), []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchGroups = async () => {
      if (isModalOpen) {
        setIsLoading(true);
        try {
          await groupService.getGroups(
            branchId,
            (data) => {
              if (!signal.aborted) {
                setGroups(data);
              }
            },
            (errorMessage) => {
              if (!signal.aborted) {
                showToast(errorMessage, "error");
              }
            }
          );
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchGroups();

    return () => {
      controller.abort();
    };
  }, [groupService, showToast, branchId, isModalOpen]);

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        title={t("groups.percentages.button")}
        onClick={() => setIsModalOpen(true)}
        sx={{
          margin: 0,
          minWidth: "2.5rem",
          maxWidth: "2.5rem",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          width: "2.5rem",
          height: "2.5rem",
          padding: "8px",
        }}
      >
        <PercentIcon />
      </Button>
      {isLoading && <Loading sidebar={false} />}
      {!isLoading && isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="Establecer porcentajes"
          aria-describedby="Establecer porcentajes"
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("groups.percentages.title")}</h1>
            </div>
            <div className="p-8">
              {!equalSplitTip ? (
                <SetPercentagesForm
                  groups={groups}
                  setIsModalOpen={setIsModalOpen}
                  branchId={branchId}
                />
              ) : (
                <p className="w-full text-center">
                  Todos los empleados cobran la misma propina
                </p>
              )}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
