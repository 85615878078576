import { useEffect, useMemo, useState } from "react";
import Star from "../icons/Star";
import { DashboardService } from "../../services/dashboard-service";
import { useToast } from "../../context/toast-context";
import { OverallRatingRes } from "../../services/dto/response/dashboard-response-dto";
import { OverallRatingSkeleton } from "../skeletons/overall-rating-skeleton";

interface OverallRatingProps {
  startDate: string;
  endDate: string;
}

export function OverallRating({ endDate, startDate }: OverallRatingProps) {
  const [data, setData] = useState<OverallRatingRes | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { showToast } = useToast();
  const dashboardService = useMemo(() => new DashboardService(), []);

  useEffect(() => {
    const fetchOverallRating = async () => {
      if (!startDate || !endDate) return;
      setIsLoading(true);
      await dashboardService.getOverallRating(
        {
          fechadesde: startDate,
          fechahasta: endDate,
        },
        (data) => {
          setData(data);
          setIsLoading(false);
        },
        (error) => {
          showToast(error, "error");
          setIsLoading(false);
        }
      );
    };
    fetchOverallRating();
  }, [dashboardService, showToast, startDate, endDate]);

  const items = useMemo(() => {
    if (!data) return [];
    const { ratings, tips } = data;

    return [
      { label: "Valoraciones", value: ratings.valoraciones },
      {
        label: "Valoración promedio",
        value: Number(ratings.promedio_rating).toFixed(2),
      },
      {
        label: "Propinas recibidas",
        value: tips.propinas_recibidas,
      },
      { label: "Propina promedio", value: `$${tips.propinas_promedio}` },
      {
        label: "Total propinas",
        value: `$${tips.propinas_total.toLocaleString()}`,
      },
    ];
  }, [data]);

  if (isLoading) {
    return <OverallRatingSkeleton />;
  }

  return (
    <div className="px-4 h-full w-full flex items-center">
      <div className="flex h-full w-full justify-center flex-col items-center">
        <div className="flex justify-between w-full  mb-2">
          {items.map((item, index) => (
            <div key={index} className="text-center w-1/5">
              <div className="text-white text-xs font-semibold tracking-wider">
                {item.label}
              </div>
            </div>
          ))}
        </div>

        {/* Linea horizontal */}
        <div className="relative w-full  flex items-center">
          <div className="border-t-[1px] border-white w-full absolute left-0"></div>
          {items.map((_, index) => (
            <div key={index} className="relative flex-1 flex justify-center">
              {index < items.length - 1 && (
                <div className="border-l-[1px] border-white h-6 absolute right-0 top-[-12px]"></div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-between w-full  mt-2">
          {items.map((item, index) => (
            <div key={index} className="text-center w-1/5">
              <div className="flex items-center justify-center ">
                <span className="flex items-center text-white font-bold text-xl">
                  {index === 1 && <Star />}
                  {item.value}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
