import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

interface StarProps extends SvgIconProps {
  isAnimating?: boolean;
}

const Star = React.forwardRef<SVGSVGElement, StarProps>(({ ...props }, ref) => (
  <SvgIcon
    sx={{
      fontSize: "1.5rem",
      ...props.sx,
    }}
    {...props}
    ref={ref}
    viewBox="-2.4 -2.4 28.8 28.8"
  >
    <path d="M9.153 5.408C10.42 3.136 11.053 2 12 2c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182.28.213.63.292 1.33.45l.636.144c2.46.557 3.689.835 3.982 1.776.292.94-.546 1.921-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18-.107.345-.071.717.001 1.46l.066.677c.253 2.617.38 3.925-.386 4.506-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452-.347 0-.674.15-1.329.452l-.595.274c-2.303 1.06-3.455 1.59-4.22 1.01-.767-.582-.64-1.89-.387-4.507l.066-.676c.072-.744.108-1.116 0-1.46-.106-.345-.345-.624-.821-1.18l-.434-.508c-1.677-1.96-2.515-2.941-2.223-3.882.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45.28-.213.46-.536.82-1.182l.328-.588Z" />
  </SvgIcon>
));

export default Star;
