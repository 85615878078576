export function Visibility() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 -0.5 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipRule="evenodd"
        stroke="#8a8a8a"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.5 12.5c0 2.485-3.134 4.5-7 4.5s-7-2.015-7-4.5S8.634 8 12.5 8s7 2.015 7 4.5Z" />
        <path d="M14.25 12.5a1.751 1.751 0 1 1-3.5 0 1.75 1.75 0 1 1 3.5 0v0Z" />
      </g>
    </svg>
  );
}
