import { Checkbox, styled } from "@mui/material";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#898989",
  "&.Mui-checked": {
    color: "#EF0BB8",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "10px",
    fontSize: "1.8rem",
  },
}));

export { CustomCheckbox };
