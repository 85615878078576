import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import Logo from "../assets/img/logos/X.svg";


export function ChangedPassword() {
  const { t } = useTranslation();
  return (
    <main className="h-screen container mx-auto">
      <div className="flex flex-col mx-auto justify-center items-center pt-32">
        <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
        <h1 className="font-normal text-center tracking-wide text-5xl py-14">
          {t("changed-password.title")}
        </h1>

        
        <Link
          className="mt-10 px-24 hover:bg-black/90 mx-auto py-1 text-white bg-black rounded-xl"
          to="/login"
        >
          LOGIN
        </Link>
      </div>
    </main>
  );
}