import { useState, useEffect, useMemo } from "react";
import { FormControl } from "@mui/material";
import { Branch } from "../../types";
import { BranchService } from "../../services/branch-service";
import { useToast } from "../../context/toast-context";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";

interface BranchSelectorProps {
  onBranchSelect: (branchId: number) => void;
  selectedBranch?: number | null;
  disabled?: boolean;
  sidebarLoader?: boolean;
}

export function BranchSelector({
  onBranchSelect,
  selectedBranch,
  sidebarLoader = true,
}: BranchSelectorProps) {
  const [branches, setBranches] = useState<Branch[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const branchService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchBranches = async () => {
      setIsLoading(true);
      try {
        await branchService.getBranches(
          { limit: 100, offset: 0 },
          (data) => {
            if (!signal.aborted) {
              setBranches(data.branches);
            }
          },
          (errorMessage) => {
            if (!signal.aborted) {
              showToast(errorMessage, "error");
            }
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    fetchBranches();

    return () => {
      controller.abort();
    };
  }, [showToast, branchService]);

  const handleBranchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    onBranchSelect(parseInt(value));
  };

  return (
    <div>
      {isLoading ? (
        <Loading sidebar={sidebarLoader} />
      ) : (
        <FormControl fullWidth>
          <select
            id="select-branch"
            className="px-2 py-1 h-full rounded-full border border-[#838383] outline-primary"
            value={selectedBranch ?? ""}
            onChange={handleBranchChange}
          >
            <option value="">{t("groups.select.label")}</option>
            {branches.map((branch) => (
              <option key={branch.branchId} value={branch.branchId}>
                {branch.name}
              </option>
            ))}
          </select>
        </FormControl>
      )}
    </div>
  );
}
