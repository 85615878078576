import { useParams } from "react-router-dom";
import { AddGroup } from "../components/teams/add-group";
import { GroupItem } from "../components/groups/group-item";
import { SetPercentages } from "../components/groups/set-percentages";
import { useTranslation } from "react-i18next";
import { Group } from "../types";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../context/toast-context";
import { GroupService } from "../services/group-service";
import { Loading } from "../components/Loading";
import { BranchSelectorModal } from "../components/modals/BranchSelectorModal";
import { BranchService } from "../services/branch-service";
import { GroupEqualSplitItem } from "../components/groups/group-equal-split-item";

interface BranchInfo {
  name: string;
  equalSplitTip: boolean;
  employeeCount: string;
  id: number;
}

export function GroupsByBranch() {
  const [groups, setGroups] = useState<Group[]>([]);
  const [branchInfo, setBranchInfo] = useState<BranchInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  const branchService = useMemo(() => new BranchService(), []);
  const groupService = useMemo(() => new GroupService(), []);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const handleAddGroup = (newGroup: Group) => {
    setGroups((prev) => [...prev, newGroup]);
  };

  const handleUpdatePercentages = (
    updatedGroups: { GroupId: number; Percent: number }[]
  ) => {
    setGroups((prev) =>
      prev.map((group) => {
        const updatedGroup = updatedGroups.find(
          (el) => el.GroupId === group.groupId
        );
        return updatedGroup
          ? {
              ...group,
              percent: updatedGroup.Percent.toString(),
            }
          : group;
      })
    );
  };

  const handleEditGroup = (updatedGroup: Group) => {
    setGroups((prev) =>
      prev.map((group) =>
        updatedGroup.groupId === group.groupId ? updatedGroup : group
      )
    );
  };

  const handleDeleteGroup = (groupId: number) => {
    setGroups((prev) => prev.filter((group) => group.groupId !== groupId));
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchGroups = async () => {
      setIsLoading(true);

      try {
        await branchService.getBranchesInformation(
          {
            limit: 100,
            offset: 0,
            branchid: params.branchId!,
          },
          (data) => {
            if (!signal.aborted) {
              setGroups(data[0].groups);
              setBranchInfo({
                equalSplitTip: data[0].equalSplitTip,
                name: data[0].name,
                employeeCount: data[0].employeeCount,
                id: data[0].branchId,
              });
            }
          },
          (errorMessage) => {
            if (!signal.aborted) {
              showToast(errorMessage, "error");
            }
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    fetchGroups();

    return () => {
      controller.abort();
    };
  }, [groupService, showToast, params.branchId, branchService]);

  return (
    <main className="h-full flex flex-col rounded-3xl bg-[#a0abba] p-6 overflow-hidden">
      {/* Contenido fijo o encabezado */}
      {isLoading && <Loading />}
      <div className="flex mb-4 justify-between">
        <div className="flex items-center gap-4">
          <h1 className="pl-6 text-2xl text-white font-medium">
            {branchInfo?.name}
          </h1>
          <BranchSelectorModal />
        </div>
        {branchInfo && branchInfo.equalSplitTip === false && (
          <div className="flex gap-2">
            <AddGroup onAddGroup={handleAddGroup} />
            <SetPercentages
              onUpdatePercentages={handleUpdatePercentages}
              groups={groups}
              branchId={branchInfo.id}
            />
          </div>
        )}
      </div>

      {/* Contenido scrollable */}
      <div className="flex flex-col flex-1 gap-2 h-full overflow-y-auto custom-scrollbar rounded-lg p-4">
        {!isLoading && groups.length === 0 && (
          <div className="w-full h-full flex justify-center items-center text-white text-2xl">
            <p>{t("groups.empty")}</p>
          </div>
        )}
        {!isLoading && branchInfo && branchInfo.equalSplitTip && (
          <GroupEqualSplitItem branchInfo={branchInfo} />
        )}
        {!isLoading &&
          groups.length > 0 &&
          !branchInfo?.equalSplitTip &&
          groups.map((group) => (
            <GroupItem
              group={group}
              key={group.groupId}
              onUpdatePercentages={handleUpdatePercentages}
              onUpdateGroup={handleEditGroup}
              groups={groups}
              onDeleteGroup={handleDeleteGroup}
            />
          ))}
      </div>
    </main>
  );
}
