import { FormEvent, useMemo, useState } from "react";
import Logo from "../assets/img/logos/X.svg";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility } from "../components/icons/Visibility";
import { VisibilityOff } from "../components/icons/VisibilityOff";
import { UserService } from "../services/user-service";
import { useToast } from "../context/toast-context";

export function ChangePassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPasswords, setShowPasswords] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [error, setError] = useState("");

  const userService = useMemo(() => new UserService(), []);
  const { showToast } = useToast();
  const location = useLocation();
  const state = location.state || undefined;
  const navigate = useNavigate();

  const handleChange =
    (field: "newPassword" | "confirmPassword") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswords({
        ...passwords,
        [field]: e.target.value,
      });
      setError("");
    };

  const togglePasswordVisibility =
    (field: "newPassword" | "confirmPassword") => () => {
      setShowPasswords({
        ...showPasswords,
        [field]: !showPasswords[field],
      });
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    if (!state) {
      navigate("/login");
    }
    if (passwords.newPassword !== passwords.confirmPassword) {
      setError(t("employee.verify.errors.password"));
      setLoading(false);
      return;
    }

    await userService.changePassword(
      {
        Password: passwords.newPassword,
        Token: state.token,
        UserId: +state.userid,
      },
      (data) => {
        navigate("/changed-password");
      },
      (error) => {
        showToast(error, "error");
        setLoading(false);
      }
    );
  };

  return (
    <main className="h-screen container mx-auto">
      <div className="flex flex-col mx-auto justify-center items-center pt-32">
        <img className="w-14 h-14" src={Logo} alt="Divix Logo" />

        <form className="mt-24 max-w-xs w-full" onSubmit={handleSubmit}>
          <div className="grid gap-2">
            <TextField
              type={showPasswords.newPassword ? "text" : "password"}
              value={passwords.newPassword}
              onChange={handleChange("newPassword")}
              label={t("change-password.new")}
              variant="standard"
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility("newPassword")}
                      >
                        {showPasswords.newPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  color: "#000",
                  fontFamily: "Montserrat",
                  "&:before": {
                    borderBottom: "1px solid #838383",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#838383",
                },
                "& .MuiInputLabel-standard": {
                  color: "#838383",
                  fontWeight: "300",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#838383",
                },
              }}
            />
            <TextField
              type={showPasswords.confirmPassword ? "text" : "password"}
              value={passwords.confirmPassword}
              onChange={handleChange("confirmPassword")}
              label={t("change-password.repeat")}
              variant="standard"
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility("confirmPassword")}
                      >
                        {showPasswords.confirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  color: "#000",
                  fontFamily: "Montserrat",
                  "&:before": {
                    borderBottom: "1px solid #838383",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#838383",
                },
                "& .MuiInputLabel-standard": {
                  color: "#838383",
                  fontWeight: "300",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#838383",
                },
              }}
            />
            {error && <div className=" text-red text-center">{error}</div>}
          </div>

          <div className="mt-12 w-full mx-auto">
            <button
              type="submit"
              disabled={loading}
              className="w-full transition-colors leading-5 bg-[#000] hover:bg-opacity-90 text-white font-light rounded-3xl py-1 px-4"
            >
              {!loading ? (
                t("change-password.submit")
              ) : (
                <CircularProgress size="1rem" sx={{ color: "#fff" }} />
              )}
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}
