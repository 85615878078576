import { Skeleton } from "@mui/material";

export function EmployeesMetricsSkeleton() {
  return (
    <ul className="p-4">
      <h2 className="font-extralight tracking-widest mt-4">MÁS VALORADOS</h2>
      <div className="shrink-0 w-full mt-2 mb-4 bg-gray-300 h-[1px]" />
      {[...Array(5)].map((_, index) => (
        <li
          key={index}
          className="bg-[#868c95] mb-4 p-2 h-32 rounded-2xl flex items-center justify-start gap-2 relative"
        >
          <div className="h-12 w-12">
            <Skeleton variant="circular" width={48} height={48} />
          </div>
          <div className="flex flex-col gap-2 justify-start max-w-xs w-full">
            <Skeleton variant="text" height={20} width="80%" />
            <div className="flex text-xs font-semibold items-center">
              <Skeleton
                variant="rectangular"
                width={48}
                height={20}
                className="mr-2 rounded-full"
              />
              <Skeleton variant="text" width="50%" height={20} />
            </div>
            <div className="flex text-xs font-semibold items-center">
              <Skeleton
                variant="rectangular"
                width={48}
                height={20}
                className="mr-2 rounded-full"
              />
              <Skeleton variant="text" width="50%" height={20} />
            </div>
            <div className="flex text-xs font-semibold items-center">
              <Skeleton
                variant="rectangular"
                width={48}
                height={20}
                className="mr-2 rounded-full"
              />
              <Skeleton variant="text" width="50%" height={20} />
            </div>
          </div>
          <div className="absolute bottom-5 right-3 flex items-center justify-center">
            <Skeleton variant="circular" width={48} height={48} />
          </div>
        </li>
      ))}
    </ul>
  );
}
