import { useEffect, useMemo, useState } from "react";
import { AddBranch } from "../components/branches/add-branch";
import { BranchItem } from "../components/branches/branch-item";
import { Loading } from "../components/Loading";
import { Branch } from "../types";
import { BranchService } from "../services/branch-service";
import { useToast } from "../context/toast-context";
import { useTranslation } from "react-i18next";

// Sucursales
export function Branches() {
  const [branches, setBranches] = useState<Branch[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const branchService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();
  const { t } = useTranslation();

  const handleAddBranch = (newBranch: Branch) => {
    setBranches((prev) => [...prev, newBranch]);
  };

  const handleDeleteBranch = (branchId: number) => {
    setBranches((prev) =>
      prev.filter((branch) => branch.branchId !== branchId)
    );
  };

  const handleUpdateBranch = (updatedBranch: Branch) => {
    setBranches((prev) =>
      prev.map((branch) =>
        // Debemos mantener el employeeCount porque la respuesta del back no la contiene
        updatedBranch.branchId === branch.branchId
          ? {
              ...updatedBranch,
              employeeCount: branch.employeeCount,
            }
          : branch
      )
    );
  };

  const handleAddEmployee = (branchId: number, amountAdded: number) => {
    setBranches((prev) =>
      prev.map((branch) =>
        branch.branchId === branchId
          ? {
              ...branch,
              employeeCount: Number(branch.employeeCount) + amountAdded,
            }
          : branch
      )
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchBranches = async () => {
      setIsLoading(true);

      try {
        await branchService.getBranches(
          { limit: 100, offset: 0 },
          (data) => {
            if (!signal.aborted) {
              setBranches(data.branches);
            }
          },
          (errorMessage) => {
            if (!signal.aborted) {
              showToast(errorMessage, "error");
            }
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    fetchBranches();

    return () => {
      controller.abort();
    };
  }, [branchService, showToast]);

  return (
    <main className="h-full flex flex-col rounded-3xl bg-[#a0abba] p-6 overflow-hidden">
      {/* Contenido encabezado */}
      <div className="mb-4 flex justify-end">
        <AddBranch onAddBranch={handleAddBranch} />
      </div>

      {/* Contenido principal */}
      <div className="flex flex-col flex-1 gap-2 h-full overflow-y-auto custom-scrollbar rounded-lg p-4">
        {isLoading && <Loading />}
        {!isLoading && branches.length === 0 && (
          <div className="w-full h-full flex justify-center items-center text-white text-2xl">
            <p>{t("branches.no-branches")}</p>
          </div>
        )}
        {!isLoading &&
          branches.length > 0 &&
          branches.map((branch, index) => (
            <BranchItem
              onUpdateBranch={handleUpdateBranch}
              onDeleteBranch={handleDeleteBranch}
              onAddEmployee={handleAddEmployee}
              number={index + 1}
              branch={branch}
              key={branch.branchId}
            />
          ))}
      </div>
    </main>
  );
}
