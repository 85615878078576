interface MonitorProps {
  stroke?: string
}

export function Monitor({
  stroke = "#fff"
}: MonitorProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="-0.96 -0.96 25.92 25.92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 17v4m-4 0h8m-9.8-4h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 15.48 21 14.92 21 13.8V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 3 18.92 3 17.8 3H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 4.52 3 5.08 3 6.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 17 5.08 17 6.2 17Z"
        stroke={stroke}
        strokeWidth=".792"
        strokeLinecap="round"
      />
    </svg>
  );
}
