import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EmployeeFirstLoginRes } from "../../../services/dto/response/user-response-dto";
import { groupTypes } from "../../../types";

interface CurrentEmployeeProps {
  employee: EmployeeFirstLoginRes;
  sameAmount: boolean;
}

export function CurrentEmployee({
  employee,
  sameAmount,
}: CurrentEmployeeProps) {
  const { t } = useTranslation();
  const emtpyGroup = !employee.userGroups || !employee.userGroups.length;

  const getGroupTypeName = (typeId: number): string => {
    const groupType = groupTypes.find((group) => group.groupTypeId === typeId);
    return groupType ? groupType.name : "";
  };

  return (
    <div className="flex justify-center mb-2 items-center px-6 gap-4">
      <div className="flex items-center w-full justify-between">
        <TextField
          type="text"
          label={t("employee.create.fullname")}
          variant="standard"
          disabled
          value={employee.name}
          sx={{
            "& .MuiInput-root": {
              color: "#dfdfdf",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #dfdfdf",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#dfdfdf",
            },
            "&.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiInputLabel-standard": {
              color: "#dfdfdf",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#dfdfdf",
            },
          }}
        />
      </div>
      <div className="flex items-center w-full mr-2 justify-between">
        <TextField
          type="email"
          label={t("employee.create.email")}
          disabled
          variant="standard"
          value={employee.email}
          sx={{
            "& .MuiInput-root": {
              color: "#dfdfdf",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #dfdfdf",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#dfdfdf",
            },
            "&.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiInputLabel-standard": {
              color: "#dfdfdf",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#dfdfdf",
            },
          }}
        />
      </div>

      {!sameAmount && (
        <>
          <div className="flex mt-6 items-center w-full justify-between">
            <div className="px-2 py-1 h-full truncate max-w-[200px] w-[200px] rounded-full border bg-transparent border-[#dfdfdf] outline-primary opacity-50">
              {employee.groupsTypes
                .map((groupType) => getGroupTypeName(groupType.typeId))
                .join(", ")}
            </div>
          </div>

          <div className="flex mt-6 items-center w-[230px] pr-2 justify-between">
            <div className="px-2 py-1 h-full truncate w-[230px] rounded-full border bg-transparent border-[#dfdfdf] outline-primary opacity-50">
              {!emtpyGroup
                ? employee.userGroups.map((group) => group.name).join(", ")
                : "Sin grupo"}
            </div>
          </div>
        </>
      )}
      <div className="flex mt-6 items-center">
        <FormControlLabel
          sx={{
            "& .MuiTypography-root": {
              color: "#dfdfdf",
              paddingLeft: "2px",
              fontFamily: "Montserrat",
            },
            "&.Mui-disabled": {
              WebkitTextFillColor: "#dfdfdf",
              opacity: "70%",
            },
            "& .MuiButtonBase-root.MuiCheckbox-root.Mui-disabled": {
              color: "#EF0BB8",
            },
          }}
          control={
            <Checkbox
              sx={{
                color: "#dfdfdf",
                padding: 0,

                "&.Mui-checked": {
                  color: "#EF0BB8",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 24,
                  margin: 0,
                  padding: 0,
                },
              }}
              disabled
              checked={employee.useQR}
            />
          }
          label="QR"
        />
      </div>
      <div className="relative ml-4" />
    </div>
  );
}
