import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeFirstLoginRes } from "../../services/dto/response/user-response-dto";
import { Group } from "../../types";
import { EditEmployeeForm } from "../form/first-login/edit-employee-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface EditTeamInterface {
  employee: EmployeeFirstLoginRes;
  groups: Group[];
  equalSplitTip: boolean;
  updateEmployees: (updatedEmployees: any) => void;
  branchId: number;
}

export function EditTeam({
  employee,
  groups,
  equalSplitTip,
  updateEmployees,
  branchId,
}: EditTeamInterface) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        title={t("employee.edit.title")}
        onClick={() => setIsModalOpen(true)}
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          padding: "0.25rem",
          width: "1.7rem",
          height: "1.7rem",
        }}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#EF0BB8">
            <path d="M8.293 3.707 1 11v4h4l7.293-7.293-4-4ZM9.707 2.293l4 4 1.465-1.465a2.829 2.829 0 0 0-4-4L9.707 2.293Z" />
          </g>
        </svg>
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby={t("")}
        aria-describedby={t("")}
      >
        <Box sx={style}>
          <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
            <h1>{t("teams.setup.edit-team")}</h1>
          </div>
          <div className="p-8">
            <EditEmployeeForm
              employee={employee}
              groups={groups}
              equalSplitTip={equalSplitTip}
              updateEmployees={updateEmployees}
              branchId={branchId}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}
