import { useTranslation } from "react-i18next";
import { ViewEmployees } from "../branches/view-employees";

interface BranchInfo {
  name: string;
  equalSplitTip: boolean;
  employeeCount: string;
  id: number;
}

interface GroupEqualSplitItemProps {
  branchInfo: BranchInfo;
}

export function GroupEqualSplitItem({ branchInfo }: GroupEqualSplitItemProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full font-light text-white flex gap-6 bg-[#2d2f32] py-4 px-6 rounded-xl">
      <div className="flex w-fit items-center  rounded-full  text-xl ">
        Todos cobran la misma propina
      </div>

      <div role="none" className="shrink-0 w-[1px] bg-white" />

      <div className="flex flex-1 justify-between items-center gap-4">
        <div className="flex justify-start items-center gap-6">
          <div className="flex gap-2 items-center ">
            <div className="text-3xl w-[3ch] flex items-center justify-center font-normal border rounded-xl border-primary px-3 py-1">
              {branchInfo.employeeCount}
            </div>
            <span>{t("branches.employees")}</span>
          </div>

          <ViewEmployees branchId={branchInfo.id} />
        </div>
      </div>
    </div>
  );
}
