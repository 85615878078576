import {
  FormHelperText,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Branch } from "../../types";
import { BranchService } from "../../services/branch-service";
import { useToast } from "../../context/toast-context";
import { ButtonForm } from "./button-form";
import { WarningModal } from "../WarningModal";
import InfoIcon from "../icons/InfoIcon";

interface EditBranchFormProps {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  branch: Branch;
  updateBranch: (branch: Branch) => void;
}

export function EditBranchForm({
  setIsModalOpen,
  branch,
  updateBranch,
}: EditBranchFormProps) {
  const { t } = useTranslation();
  const [branchName, setBranchName] = useState<string>(branch.name);
  const [branchAddress, setBranchAddress] = useState<string>(branch.address);
  const [branchPhone, setBranchPhone] = useState<string>(branch.phone);
  const [branchEqualSplit, setBranchEqualSplit] = useState(
    branch.equalSplitTip
  );
  const [onlyRatings, setOnlyRatings] = useState(branch.onlyRatings);
  const [googleReviewLink, setGoogleReviewLink] = useState<string | null>(
    branch.googleLink
  );

  const [errors, setErrors] = useState({
    branchName: "",
    branchAddress: "",
    branchPhone: "",
  });
  const [loading, setLoading] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const { showToast } = useToast();
  const branchService = useMemo(() => new BranchService(), []);

  const validate = () => {
    let data = { branchName: "", branchAddress: "", branchPhone: "" };
    let isValid = true;

    if (!branchName || branchName.length < 3) {
      data.branchName = t("branches.edit.errors.name");
      isValid = false;
    }

    if (!branchAddress || branchAddress.length < 5) {
      data.branchAddress = t("branches.edit.errors.address");
      isValid = false;
    }

    if (!branchPhone) {
      data.branchPhone = t("branches.create.errors.phone");
      isValid = false;
    }

    setErrors(data);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    if (!validate()) {
      setLoading(false);
      return;
    }

    const originalEqualSplitTip = branch.equalSplitTip;
    const currentEqualSplitTip = branchEqualSplit;

    await branchService.updateBranch(
      {
        BranchId: branch.branchId,
        Address: branchAddress,
        Name: branchName,
        Phone: branchPhone,
        EqualSplitTip: currentEqualSplitTip,
        GoogleLink: googleReviewLink,
        OnlyRatings: onlyRatings,
      },
      (data) => {
        updateBranch(data[0]);

        if (
          originalEqualSplitTip !== currentEqualSplitTip &&
          !currentEqualSplitTip
        ) {
          setLoading(false);
          setIsWarningModalOpen(true);
        } else {
          setLoading(false);
          setIsModalOpen && setIsModalOpen(false);
          showToast(t("branches.edit.success"), "success");
        }
      },
      (error) => {
        setLoading(false);
        setIsModalOpen && setIsModalOpen(false);
        showToast(error, "error");
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid px-6 gap-4">
          <TextField
            type="text"
            label={t("branches.edit.name")}
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            error={!!errors.branchName}
            helperText={errors.branchName}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
          <TextField
            type="text"
            label={t("branches.edit.address")}
            value={branchAddress}
            onChange={(e) => setBranchAddress(e.target.value)}
            error={!!errors.branchAddress}
            helperText={errors.branchAddress}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />

          <TextField
            type="text"
            label={t("branches.edit.phone")}
            value={branchPhone}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              let value = input.value;

              value = value.replace(/[^\d\s+]/g, ""); // Permitimos numeros, espacios y +
              value = value.replace(/\s+/g, " "); // No permitimos mas de 1 espacio consecutivo

              // Si ingresa + que sea lo primero de la cadena
              value = value.replace(/(?!^)\+/g, "");
              if (value.includes("+") && value[0] !== "+") {
                value = "+" + value.replace(/\+/g, "");
              }

              setBranchPhone(value);
            }}
            error={!!errors.branchPhone}
            helperText={errors.branchPhone}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />

          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col ">
              <label htmlFor="equalSplit">
                {t("branches.create.with-equal-split")}
              </label>
              <FormHelperText
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                {t("branches.edit.split-helper")}
              </FormHelperText>
            </div>
            <Switch
              id="equalSplit"
              name="equalSplit"
              checked={branchEqualSplit}
              onChange={(e) => setBranchEqualSplit(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  bgcolor: "#EF0BB8",
                },
                "& .MuiSwitch-track": {
                  bgcolor: "#898989",
                },
                "& .MuiSwitch-thumb": {
                  bgcolor: "#EF0BB8",
                },
              }}
            />
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col ">
              <label htmlFor="qr">{t("branches.create.only-review")}</label>
              <FormHelperText
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                {t("branches.create.only-review-note")}
              </FormHelperText>
            </div>
            <Switch
              id="qr"
              name="qr"
              checked={onlyRatings}
              onChange={(e) => setOnlyRatings(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  bgcolor: "#EF0BB8",
                },
                "& .MuiSwitch-track": {
                  bgcolor: "#898989",
                },
                "& .MuiSwitch-thumb": {
                  bgcolor: "#EF0BB8",
                },
              }}
            />
          </div>

          <div className="w-full flex flex-col">
            <TextField
              type="text"
              fullWidth
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              placeholder="https://g.page/your-business/review"
              label={
                <div>
                  {t("branches.create.google.title")}
                  <Tooltip
                    title={
                      <p style={{ fontFamily: "Montserrat" }}>
                        {t("branches.create.google.tooltip")}
                      </p>
                    }
                    placement="top"
                  >
                    <IconButton>
                      <InfoIcon
                        sx={{
                          fill: "#fff",
                          height: 16,
                          width: 16,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              }
              value={googleReviewLink ?? ""}
              onChange={(e) => setGoogleReviewLink(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInput-root": {
                  color: "#000",
                  fontFamily: "Montserrat",
                  "&:before": {
                    borderBottom: "1px solid #838383",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#7e7e7e",
                },
                "& .MuiInputLabel-standard": {
                  color: "#000",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#838383",
                },
              }}
            />
            <a
              href="https://support.google.com/business/answer/3474122?hl=es-419"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline mt-4 text-sm w-fit"
            >
              {t("branches.create.google.link")}
            </a>
          </div>
        </div>

        <div className="flex justify-center mt-8 mx-6">
          <ButtonForm loading={loading}>{t("branches.edit.submit")}</ButtonForm>
        </div>
      </form>
      {isWarningModalOpen && (
        <WarningModal
          open={isWarningModalOpen}
          title={t("warning")}
          message={t("branches.edit.change-split")}
          onClose={() => {
            setIsWarningModalOpen(false);
            setIsModalOpen && setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
}
