import { CircularProgress } from "@mui/material";

interface LoadingProps {
  sidebar?: boolean;
}

export function Loading({ sidebar = true }: LoadingProps) {
  return (
    <div
      className={`fixed inset-0 z-40 flex items-center justify-center bg-[rgba(200,200,200,0.2)] backdrop-blur-sm ${
        sidebar ? "ml-52" : ""
      }`}
    >
      <CircularProgress
        sx={{
          color: "#ef0bb8",
        }}
        className="animate-spin h-10 w-10 text-primary"
      />
    </div>
  );
}
