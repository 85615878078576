import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Settings } from "../../types";

interface FormSettingsProps {
  settings: Settings;
}

export function FormSettings({ settings }: FormSettingsProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-20 flex flex-col justify-center gap-4 items-start">
      <TextField
        id={t("settings.form.company")}
        label={t("settings.form.company")}
        variant="standard"
        fullWidth
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={settings.name}
        type="text"
        sx={{
          "& .MuiInput-root": {
            fontFamily: "Montserrat",
            "&:before": {
              borderBottom: "1px solid #838383",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#898989",
            },
          },

          "& .MuiInputLabel-standard": {
            color: "#000",
            fontWeight: "300",
            fontFamily: "Montserrat",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#838383",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
            color: "#000",
          },
          "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            opacity: "70%",
          },
        }}
      />
      <TextField
        id="cuit"
        label="CUIT"
        variant="standard"
        fullWidth
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={settings.cuit ?? ""}
        type="text"
        sx={{
          "& .MuiInput-root": {
            fontFamily: "Montserrat",
            "&:before": {
              borderBottom: "1px solid #838383",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#898989",
            },
          },

          "& .MuiInputLabel-standard": {
            color: "#000",
            fontWeight: "300",
            fontFamily: "Montserrat",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#838383",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
            color: "#000",
          },
          "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            opacity: "70%",
          },
        }}
      />
      <TextField
        id={t("settings.form.address")}
        label={t("settings.form.address")}
        variant="standard"
        fullWidth
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={settings.address ?? ""}
        type="text"
        sx={{
          "& .MuiInput-root": {
            fontFamily: "Montserrat",
            "&:before": {
              borderBottom: "1px solid #838383",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#898989",
            },
          },

          "& .MuiInputLabel-standard": {
            color: "#000",
            fontWeight: "300",
            fontFamily: "Montserrat",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#838383",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
            color: "#000",
          },
          "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            opacity: "70%",
          },
        }}
      />
      <TextField
        id={t("settings.form.phone")}
        label={t("settings.form.phone")}
        variant="standard"
        fullWidth
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={settings.phone ?? ""}
        type="text"
        sx={{
          "& .MuiInput-root": {
            fontFamily: "Montserrat",
            "&:before": {
              borderBottom: "1px solid #838383",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#898989",
            },
          },

          "& .MuiInputLabel-standard": {
            color: "#000",
            fontWeight: "300",
            fontFamily: "Montserrat",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#838383",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
            color: "#000",
          },
          "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            opacity: "70%",
          },
        }}
      />
      <TextField
        id={t("settings.form.responsible")}
        label={t("settings.form.responsible")}
        variant="standard"
        fullWidth
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={settings.responsible ?? ""}
        type="text"
        sx={{
          "& .MuiInput-root": {
            fontFamily: "Montserrat",
            "&:before": {
              borderBottom: "1px solid #838383",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#898989",
            },
          },

          "& .MuiInputLabel-standard": {
            color: "#000",
            fontWeight: "300",
            fontFamily: "Montserrat",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#838383",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
            color: "#000",
          },
          "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            opacity: "70%",
          },
        }}
      />
      <TextField
        id="email"
        label="Email"
        variant="standard"
        fullWidth
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={settings.email ?? ""}
        type="email"
        sx={{
          "& .MuiInput-root": {
            fontFamily: "Montserrat",
            "&:before": {
              borderBottom: "1px solid #838383",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#898989",
            },
          },

          "& .MuiInputLabel-standard": {
            color: "#000",
            fontWeight: "300",
            fontFamily: "Montserrat",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#838383",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
            color: "#000",
          },
          "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            opacity: "70%",
          },
        }}
      />
    </div>
  );
}
