import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { groupTypes } from "../../../types";
import { useToast } from "../../../context/toast-context";
import {
  AddEmployeeFirstLoginReq,
  GroupFirstLogin,
} from "../../../services/dto/request/user-request-dto";
import { UserService } from "../../../services/user-service";
import { ButtonForm } from "../button-form";
import { Add } from "../../icons/Add";
import { useNavigate } from "react-router-dom";
import { Delete } from "../../icons/Delete";
import { CurrentEmployee } from "./current-employees-form";
import { BranchInfo } from "../../../screens/SetupTeam";
import { EmployeeFirstLoginRes } from "../../../services/dto/response/user-response-dto";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

type AddEmployeeFirstLogin = {
  name: string;
  email: string;
  hasQR: boolean;
  groupsNames: string[];
  groupTypes: string[];
};

interface SetupTeamFormProps {
  branchNumberId?: number;
  branchName: string;
  employees: EmployeeFirstLoginRes[];
  initialGroups: GroupFirstLogin[];
  branchesInfo: BranchInfo;
}

export function SetupTeamForm({
  branchNumberId,
  branchName,
  employees,
  initialGroups,
  branchesInfo,
}: SetupTeamFormProps) {
  const { t } = useTranslation();
  const [sameAmount, setSameAmount] = useState(false);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localGroups, setLocalGroups] = useState<GroupFirstLogin[]>([]);
  const [newEmployees, setNewEmployees] = useState<AddEmployeeFirstLogin[]>([
    {
      name: "",
      email: "",
      groupsNames: [],
      hasQR: false,
      groupTypes: [],
    },
  ]);

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const userService = useMemo(() => new UserService(), []);
  const navigate = useNavigate();
  const { showToast } = useToast();

  const allGroups: GroupFirstLogin[] = [
    ...localGroups,
    ...initialGroups.map((group) => ({
      groupId: group.groupId,
      name: group.name,
    })),
  ];

  const addEmployeeRow = () => {
    // Verificamos si el último row tiene todos los campos completados para evitar agregar innecesariamente
    const lastEmployee = newEmployees[newEmployees.length - 1];
    if (lastEmployee.name.trim() !== "" && lastEmployee.email.trim() !== "") {
      setNewEmployees([
        ...newEmployees,
        {
          name: "",
          email: "",
          groupsNames: [],
          hasQR: false,
          groupTypes: [],
        },
      ]);
    } else {
      setError(t("branches.create.errors.complete-row"));
    }
  };

  const removeEmployeeRow = (index: number) => {
    setNewEmployees(newEmployees.filter((_, i) => i !== index));
  };
  const handleInputChange = (
    index: number,
    field: keyof AddEmployeeFirstLogin,
    value: string | null
  ) => {
    const updatedEmployees = [...newEmployees];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      [field]: value === "-1" ? null : value,
    };
    setNewEmployees(updatedEmployees);
    setError("");
  };

  const handleGroupChange = (index: number, value: string[]) => {
    if (value.includes("add-new-group")) {
      setIsModalOpen(true);
    } else {
      const updatedEmployees = [...newEmployees];
      updatedEmployees[index].groupsNames = value;
      setNewEmployees(updatedEmployees);
    }
  };

  const handleCreateGroup = (groupName: string) => {
    setError("");

    const isDuplicate = allGroups.some(
      (groups) => groups.name.toLowerCase() === groupName.toLowerCase()
    );

    if (isDuplicate) {
      setError(t("teams.group.create.errors.duplicated"));
      return;
    }

    if (!groupName) return;
    const newGroup: GroupFirstLogin = {
      groupId: -1,
      name: groupName,
    };

    setLocalGroups([...localGroups, newGroup]);
    setIsModalOpen(false);
  };

  const handleGroupTypeChange = (index: number, value: string[]) => {
    const updatedEmployees = [...newEmployees];
    updatedEmployees[index].groupTypes = value;
    updatedEmployees[index].groupsNames = value.includes("Grupo")
      ? updatedEmployees[index].groupsNames
      : [];

    updatedEmployees[index].hasQR = value.includes("Con QR");

    setNewEmployees(updatedEmployees);
    setError("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    if (
      employees.length <= 0 &&
      sameAmount &&
      !newEmployees.some((employee) => employee.hasQR)
    ) {
      setError(t("employee.create.errors.qr"));
      setLoading(false);
      return;
    }

    const formattedEmployees = newEmployees.map((employee) => ({
      name: employee.name,
      email: employee.email,
      useQR: employee.hasQR,
      groupTypesQR: sameAmount ? false : employee.groupTypes.includes("Con QR"),
      groupsNames: employee.groupsNames,
    }));

    const params: AddEmployeeFirstLoginReq = {
      branchId: +branchNumberId!,
      equalSplitTip: sameAmount,
      groups: allGroups,
      employees: formattedEmployees,
    };
    await userService.addEmployees(
      params,
      async (data) => {
        await userService.getEmployees(
          {
            limit: 100,
            offset: 0,
          },
          (data) => {
            navigate("/your-team", {
              state: {
                branchId: branchNumberId,
                branchName,
              },
            });
          },
          (error) => showToast(error, "error")
        );

        showToast(t("employee.create.success"), "success");
      },
      (error) => showToast(error, "error")
    );

    setLoading(false);
  };

  useEffect(() => {
    if (employees.length > 0) {
      const shouldEnableSameAmount = branchesInfo.equalSplitTip;

      if (shouldEnableSameAmount) {
        setSameAmount(true);
      }
    }
  }, [employees.length, branchesInfo.equalSplitTip]);

  return (
    <>
      <form className="min-w-80" onSubmit={handleSubmit}>
        <div className="px-6 pb-4">
          <FormControlLabel
            sx={{
              "& .MuiTypography-root": {
                fontFamily: "Montserrat",
                color: "#dfdfdf",
              },
              "& .MuiFormControlLabel-label.Mui-disabled": {
                color: "#898989",
              },
            }}
            control={
              <Switch
                checked={sameAmount}
                disabled={employees.length > 0}
                onChange={(e) => setSameAmount(e.target.checked)}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    bgcolor: "#EF0BB8",
                  },
                  "& .MuiSwitch-track": {
                    bgcolor: "#898989",
                  },
                  "& .MuiSwitch-thumb": {
                    bgcolor: "#EF0BB8",
                  },
                }}
              />
            }
            label={t("branches.create.with-equal-split")}
          />
        </div>
        <div>
          {employees.map((employee) => (
            <CurrentEmployee
              key={employee.email}
              employee={employee}
              sameAmount={sameAmount}
            />
          ))}

          {newEmployees.map((newEmployee, index) => (
            <div
              className={`flex justify-center items-center px-6 gap-4`}
              key={index}
            >
              <div className="flex items-center w-full justify-between">
                <TextField
                  type="text"
                  label={t("employee.create.fullname")}
                  variant="standard"
                  required
                  value={newEmployee.name}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                  sx={{
                    "& .MuiInput-root": {
                      color: "#dfdfdf",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #dfdfdf",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#898989",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#dfdfdf",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#dfdfdf",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#dfdfdf",
                    },
                  }}
                />
              </div>
              <div className="flex items-center w-full mr-2 justify-between">
                <TextField
                  type="email"
                  label={t("employee.create.email")}
                  required
                  variant="standard"
                  value={newEmployee.email}
                  onChange={(e) =>
                    handleInputChange(index, "email", e.target.value)
                  }
                  sx={{
                    "& .MuiInput-root": {
                      color: "#dfdfdf",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #dfdfdf",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#898989",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#dfdfdf",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#dfdfdf",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#dfdfdf",
                    },
                  }}
                />
              </div>

              {!sameAmount && (
                <>
                  <div className="flex mt-6 items-center w-full max-w-[200px] justify-between">
                    <Select
                      sx={{
                        paddingX: 2,
                        paddingY: 1,
                        margin: 0,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        fontSize: "1rem",
                        height: "100%",
                        width: "200px",
                        minWidth: "100%",
                        maxWidth: "230px",
                        borderRadius: "9999px",
                        border: "1px solid",
                        borderColor: "#dfdfdf",
                        backgroundColor: "transparent",
                        outline: "none",
                        "&:focus": {
                          outlineColor: "primary.main",
                        },
                        "& .MuiSelect-select": {
                          padding: 0,
                          display: "block",
                          minWidth: 0,
                        },
                        "&.MuiInputBase-root.MuiOutlinedInput-root": {
                          padding: "4px 8px",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                          {
                            WebkitTextFillColor: "#dfdfdf",
                            opacity: "70%",
                          },
                        "& .MuiSvgIcon-root.MuiSelect-icon": {
                          color: "white",
                        },
                        color: "#dfdfdf",
                        fontWeight: "400",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: "#1d1d1d",
                            color: "#fff",
                          },
                        },
                      }}
                      value={newEmployee.groupTypes ?? ""}
                      required
                      multiple
                      input={<OutlinedInput />}
                      displayEmpty
                      onChange={(e) =>
                        handleGroupTypeChange(index, e.target.value as string[])
                      }
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span className="block truncate ">Seleccionar</span>
                          );
                        }

                        return selected.join(", ");
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontFamily: "Montserrat",
                        }}
                        disabled
                        value=""
                      >
                        <span>Seleccionar</span>
                      </MenuItem>
                      {groupTypes.map((type) => (
                        <MenuItem
                          sx={{
                            fontFamily: "Montserrat",
                          }}
                          key={type.groupTypeId}
                          value={type.name}
                        >
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="flex mt-6 items-center w-full max-w-[230px] pr-2 justify-between">
                    <Select
                      sx={{
                        paddingX: 2,
                        paddingY: 1,
                        margin: 0,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        fontSize: "1rem",
                        height: "100%",
                        width: "230px",
                        minWidth: "100%",
                        maxWidth: "230px",
                        borderRadius: "9999px",
                        border: "1px solid",
                        borderColor: "#dfdfdf",
                        backgroundColor: "transparent",
                        outline: "none",
                        "&:focus": {
                          outlineColor: "primary.main",
                        },
                        "& .MuiSelect-select": {
                          padding: 0,
                          display: "block",
                          minWidth: 0,
                        },
                        "&.MuiInputBase-root.MuiOutlinedInput-root": {
                          padding: "4px 8px",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                          {
                            WebkitTextFillColor: "#dfdfdf",
                            opacity: "70%",
                          },
                        "& .MuiSvgIcon-root.MuiSelect-icon": {
                          color: "white",
                        },
                        color: "#dfdfdf",
                        fontWeight: "400",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: "#1d1d1d",
                            color: "#fff",
                          },
                        },
                      }}
                      required
                      multiple
                      input={<OutlinedInput />}
                      disabled={!newEmployee.groupTypes?.includes("Grupo")}
                      value={newEmployee.groupsNames}
                      displayEmpty
                      onChange={(e) =>
                        handleGroupChange(index, e.target.value as string[])
                      }
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span className="block truncate ">
                              Seleccione grupos
                            </span>
                          );
                        }

                        return selected.join(", ");
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontFamily: "Montserrat",
                        }}
                        disabled
                        value=""
                      >
                        <span>{t("employee.create.group")}</span>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontFamily: "Montserrat",
                        }}
                        value="add-new-group"
                      >
                        {t("groups.percentages.setup.group")}
                      </MenuItem>
                      {allGroups
                        .filter((group) => group.name !== "QR")
                        .map((group) => (
                          <MenuItem
                            sx={{
                              fontFamily: "Montserrat",
                            }}
                            key={group.name}
                            value={group.name}
                          >
                            {group.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </>
              )}
              <div className="flex mt-6 items-center">
                <FormControlLabel
                  sx={{
                    "& .MuiTypography-root": {
                      color: "#dfdfdf",
                      paddingLeft: "2px",
                      fontFamily: "Montserrat",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#dfdfdf",
                        padding: 0,
                        "&.Mui-checked": {
                          color: "#EF0BB8",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 24,
                          margin: 0,
                          padding: 0,
                        },
                      }}
                      onChange={(e) => {
                        setError("");
                        if (!newEmployee.groupTypes.includes("Con QR")) {
                          const updatedEmployees = [...newEmployees];
                          updatedEmployees[index].hasQR = e.target.checked;
                          setNewEmployees(updatedEmployees);
                        }
                      }}
                      checked={
                        newEmployee.hasQR ||
                        (!sameAmount &&
                          newEmployee.groupTypes.includes("Con QR"))
                      }
                    />
                  }
                  label="QR"
                />
              </div>
              <div className="relative ml-4">
                {index !== 0 && (
                  <IconButton
                    aria-label="Eliminar empleado"
                    onClick={() => removeEmployeeRow(index)}
                    color="secondary"
                    sx={{
                      position: "absolute",
                      right:
                        index === newEmployees.length - 1 ? "-45px" : "-10px",
                      top: "50%",
                      transform: "translateY(-27%)",
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
                {index === newEmployees.length - 1 && (
                  <IconButton
                    aria-label="Agregar empleado"
                    onClick={addEmployeeRow}
                    color="primary"
                    sx={{
                      position: "absolute",
                      right: "-10px",
                      top: "50%",
                      transform: "translateY(-27%)",
                    }}
                  >
                    <Add />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
        </div>

        {error && <div className="mt-4 text-red text-center">{error}</div>}

        <div className="flex justify-center mt-12 mx-6">
          <div className="flex items-center gap-4 w-full max-w-md">
            <ButtonForm loading={loading}>
              {t("employee.create.submit")}
            </ButtonForm>
          </div>
        </div>
      </form>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby={t("teams.group.create.title")}
          aria-describedby={t("teams.group.create.title")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("teams.group.create.title")}</h1>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateGroup(newGroupName);
              }}
              className="p-8"
            >
              <div className="grid px-6 gap-4">
                <TextField
                  type="text"
                  name="name"
                  label={t("teams.group.create.name")}
                  error={!!error}
                  helperText={error}
                  onChange={(e) => {
                    setError("");
                    setNewGroupName(e.target.value);
                  }}
                  value={newGroupName}
                  variant="standard"
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#898989",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#7e7e7e",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#000",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
              </div>

              <div className="flex justify-center mt-12 mx-6">
                <Button
                  type="submit"
                  sx={{
                    padding: "4px 8px",
                    width: "100%",
                    background: "#EF0BB8",
                    borderRadius: "16px",
                    color: "white",
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    fontWeight: 300,
                    "&.Mui-disabled": {
                      opacity: "40%",
                      color: "#fff",
                    },
                  }}
                >
                  {t("teams.group.create.submit")}
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </>
  );
}
