import React, { useEffect, useMemo, useState } from "react";
import Logo from "../assets/img/logos/X.svg";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { ButtonForm } from "../components/form/button-form";
import { useToast } from "../context/toast-context";
import { UserService } from "../services/user-service";

export function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { showToast } = useToast();
  const userService = useMemo(() => new UserService(), []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    await userService.sendRecoverEmail(
      {
        email,
      },
      () => {
        showToast(t("forgot-password.success"), "success");
        setCountdown(30);
        setLoading(false);
      },
      (error) => {
        showToast(error, "error");
        setLoading(false);
        setCountdown(30);
      }
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <main className="h-screen container mx-auto">
      <div className="flex flex-col mx-auto justify-center items-center pt-24">
        <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
        <h1 className="font-normal max-w-xl text-center tracking-wide text-5xl pt-14">
          {t("forgot-password.title")}
        </h1>
        <p className="pt-10 pb-5 text-center font-medium max-w-sm">
          {t("forgot-password.subtitle")}
        </p>
        <form
          onSubmit={sendEmail}
          className="w-full flex flex-col gap-4 max-w-sm"
        >
          <TextField
            type="email"
            label={t("employee.create.email")}
            required
            placeholder={t("forgot-password.placeholder")}
            variant="standard"
            value={email}
            onChange={handleEmailChange}
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
          <div className="mt-12">
            <ButtonForm
              loading={loading}
              disabled={!email || countdown > 0}
              bgColor="#000"
            >
              {t("forgot-password.button")}
            </ButtonForm>
          </div>
          {countdown > 0 && (
            <p className="text-center text-sm text-gray-500">
              {t("forgot-password.resend-message")} {countdown}{" "}
              {t("forgot-password.seconds")}
            </p>
          )}
        </form>
      </div>
    </main>
  );
}
