import BaseService from "./base-service";
import { RequestDto } from "./dto/request/request-dto";
import { LoginResponseDTO } from "./dto/response/auth/login-response-dto";

interface LoginDTO {
  Email: string;
  Password: string;
}

export class AuthService extends BaseService {
  private resource = "/auth";

  async login(
    params: LoginDTO,
    onSuccess: (data: LoginResponseDTO) => void,
    onError: (errorMessage: string) => void
  ): Promise<void> {
    const reqParams = new RequestDto(this.resource + "/login", params);
    await this.doPost<LoginResponseDTO>(reqParams, onSuccess, onError);
  }
}
