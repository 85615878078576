import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddBranchForm } from "../components/form/first-login/add-branch-form";
import { Link, useNavigate } from "react-router-dom";
import DivixLogo from "../assets/img/logos/divixBlanco.png";
import { Branch } from "../types";

const style = {
  width: "500px",
  maxWidth: 1200,
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

export function SetupBranch() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAddBranch = (newBranch: Branch) => {
    navigate("/setup-team", {
      state: {
        branchId: newBranch.branchId,
        branchName: newBranch.name,
      },
    });
  };

  return (
    <div className="w-full min-h-screen h-full relative bg-dark-gradient-to-b">
      <Link to="/dashboard" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>

      <div className="absolute top-6 right-6">
        <Button
          type="button"
          onClick={() => navigate("/dashboard")}
          sx={{
            padding: "2px 8px",
            width: "12rem",
            background: "#EF0BB8",
            borderRadius: "16px",
            color: "white",
            textTransform: "none",
            fontFamily: "Montserrat",
            fontWeight: 300,
            "&.Mui-disabled": {
              opacity: "40%",
              color: "#fff",
            },
          }}
        >
          {t("skip")}
        </Button>
      </div>
      <main className="container mx-auto">
        <div className="flex flex-col gap-8 text-white mx-auto justify-center items-center pt-16 3xl:pt-32">
          <h1 className="font-light text-center tracking-wide text-3xl">
            {t("branches.setup.title")}
          </h1>
          <Box sx={style} className="custom-scrollbar">
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-8">
              <h1>{t("branches.setup.description")}</h1>
            </div>
            <div className="px-8 py-4">
              <AddBranchForm addBranch={onAddBranch} />
            </div>
          </Box>
        </div>
      </main>
    </div>
  );
}
