import { Box, Button, Modal } from "@mui/material";
import { SetPercentagesForm } from "./set-percentages-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Group } from "../../types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface SetPercentagesProps {
  groups: Group[];
  onUpdatePercentages: (
    updatedGroups: {
      GroupId: number;
      Percent: number;
    }[]
  ) => void;
  branchId: number;
}

export function SetPercentages({
  groups,
  onUpdatePercentages,
  branchId,
}: SetPercentagesProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        disabled={groups.length < 1}
        onClick={() => setIsModalOpen(true)}
        sx={{
          background: "#2d2f32",
          margin: 0,
          padding: "0 10px",
          borderRadius: "999px",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          "&.Mui-disabled": {
            backgroundColor: "rgba(45, 47, 50, 0.5)",
            color: "rgba(255, 255, 255, 0.5)",
            "& svg": {
              opacity: 0.5,
            },
          },
        }}
        endIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#EF0BB8"
            strokeWidth="0"
          >
            <path
              d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
              fill="#EF0BB8"
              stroke="none"
            />
          </svg>
        }
      >
        {t("groups.percentages.button")}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="Establecer porcentajes"
          aria-describedby="Establecer porcentajes"
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("groups.percentages.title")}</h1>
            </div>
            <div className="p-8">
              <SetPercentagesForm
                groups={groups}
                updatePercentages={onUpdatePercentages}
                setIsModalOpen={setIsModalOpen}
                branchId={branchId}
              />
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
