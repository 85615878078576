import { Skeleton } from "@mui/material";

export function OverallRatingSkeleton() {
  return (
    <div className="px-4 h-full w-full flex items-center">
      <div className="flex h-full w-full justify-center flex-col items-center">
        <div className="flex justify-between w-full mb-2">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="text-center w-1/5">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                sx={{
                  margin: "0 auto",
                }}
              />
            </div>
          ))}
        </div>

        {/* Línea horizontal con Skeleton */}
        <div className="relative w-full flex items-center">
          <div className="border-t-[1px] border-white w-full absolute left-0"></div>
          {[...Array(5)].map((_, index) => (
            <div key={index} className="relative flex-1 flex justify-center">
              {index < 4 && (
                <div className="border-l-[1px] border-white h-6 absolute right-0 top-[-12px]"></div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-between w-full mt-2">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="text-center w-1/5">
              <Skeleton
                variant="rectangular"
                width={40}
                height={30}
                sx={{
                  margin: "0 auto",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
