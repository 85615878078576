import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import CalendarIcon from "../icons/CalendarIcon";
import { DashboardFilterOption } from "../../types";

interface DashboardFilterProps {
  value: DashboardFilterOption;
  onChange: (newValue: DashboardFilterOption) => void;
}

export function DashboardFilter({ onChange, value }: DashboardFilterProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as DashboardFilterOption);
  };
  return (
    <div className="flex flex-col gap-1">
      <p className="uppercase font-bold text-gray-dark text-sm">Ver por</p>
      <FormControl fullWidth size="small" className="bg-white rounded">
        <Select
          value={value}
          onChange={handleChange}
          className="text-sm custom-select"
          renderValue={(selected) => (
            <div className="flex items-center gap-2 w-28">
              <CalendarIcon
                sx={{
                  fill: "#fff",
                }}
              />
              <span>
                {selected === "hour"
                  ? "Hora"
                  : selected === "day"
                  ? "Día"
                  : selected === "week"
                  ? "Semana"
                  : "Mes"}
              </span>
            </div>
          )}
          sx={{
            fontFamily: "Montserrat",
            borderRadius: "14px",
            color: "#666 ",
          }}
        >
          <MenuItem
            sx={{
              fontFamily: "Montserrat",
              color: "#666 ",
            }}
            value="hour"
          >
            Hora
          </MenuItem>
          <MenuItem
            sx={{
              fontFamily: "Montserrat",
            }}
            value="day"
          >
            Día
          </MenuItem>
          <MenuItem
            sx={{
              fontFamily: "Montserrat",
            }}
            value="week"
          >
            Semana
          </MenuItem>
          <MenuItem
            sx={{
              fontFamily: "Montserrat",
            }}
            value="month"
          >
            Mes
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
