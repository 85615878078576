import { useTranslation } from "react-i18next";
import { Group } from "../../types";
import { DeleteGroup } from "./delete-group";
import { EditGroup } from "./edit-group";
import { ViewEmployees } from "../branches/view-employees";

interface GroupItemProps {
  group: Group;
  onUpdatePercentages: (
    updatedGroups: { GroupId: number; Percent: number }[]
  ) => void;
  onUpdateGroup: (updatedGroup: Group) => void;
  groups: Group[];
  onDeleteGroup: (groupId: number) => void;
}

export function GroupItem({
  group,
  onUpdatePercentages,
  onUpdateGroup,
  onDeleteGroup,
  groups,
}: GroupItemProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full font-light text-white flex gap-6 bg-[#2d2f32] py-4 px-6 rounded-xl">
      <div className="flex w-fit items-center basis-1/6 rounded-full  text-xl ">
        {group.name}
      </div>

      <div role="none" className="shrink-0 w-[1px]  bg-white" />

      <div className="flex items-center basis-1/6 justify-center text-4xl font-normal">
        {Number(parseFloat(group.percent).toFixed(2))}%
      </div>

      <div role="none" className="shrink-0 w-[1px] bg-white" />

      <div className="flex flex-1 justify-between items-center gap-4">
        <div className="flex justify-start items-center gap-6">
          <div className="flex gap-2 items-center ">
            <div className="text-3xl w-[3ch] flex items-center justify-center font-normal border rounded-xl border-primary px-3 py-1">
              {group.employeeCount}
            </div>
            <span>{t("branches.employees")}</span>
          </div>

          <ViewEmployees branchId={group.branchId} groupId={group.groupId} />
        </div>

        {group.name !== "QR" && (
          <div className="flex items-center gap-8">
            <EditGroup group={group} updateGroup={onUpdateGroup} />
            <DeleteGroup
              group={group}
              groups={groups}
              onUpdatePercentages={onUpdatePercentages}
              onDeleteGroup={onDeleteGroup}
            />
          </div>
        )}
      </div>
    </div>
  );
}
