import { Avatar, Box, Button, Grid2, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeUser } from "../../types";
import QRCode from "react-qr-code";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: "20px",
};

interface QRModalProps {
  employee: EmployeeUser;
}

export function QRModal({ employee }: QRModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const URL = process.env.REACT_APP_LANDING_URL;

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        title="QR"
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          width: "2.5rem",
          height: "2.5rem",
        }}
      >
        <svg
          fill="#EF0BB8"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 15v2H3v-2Zm-2 6h2v-2H3Zm2-4v2h2v-2Zm4-6H7v2H5v2h4Zm-6 0v2h2v-2Zm6 8H7v2h5v-2h-1v-2H9Zm4-15h-2v2h2Zm-2 7h2V8h-2ZM4 9a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1Zm1-2h2V5H5Zm16-3v8h-2V9h-3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1Zm-2 1h-2v2h2Zm2 11v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-3h-4v-4h2v2h1v-4h2v4h4a1 1 0 0 1 1 1Zm-2 1h-2v2h2Z" />
        </svg>
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby={t("teams.group.create.title")}
        aria-describedby={t("teams.group.create.title")}
      >
        <Box sx={style}>
          <Box className="container mx-auto tip-box">
            <Grid2 container className="w-full h-1/5 header">
              <Grid2 size={4} className="avatar">
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    top: "20px",
                    position: "absolute",
                  }}
                  src={
                    employee.imageUrl ??
                    "https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
                  }
                  alt="Avatar"
                />
              </Grid2>
              <Grid2 size={8} className="header-name">
                <div className="pl-4 pb-4 flex flex-col">
                  <span className="name leading-5">{employee.name}</span>
                </div>
              </Grid2>
            </Grid2>
            <div className="p-2 h-3/5 w-full flex items-center justify-center">
              <QRCode
                size={256}
                style={{ height: "80%", maxWidth: "100%", width: "100%" }}
                value={`${URL}/?userId=${employee.userId}&branchId=${employee.branchId}`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div className="flex justify-center mt-5 mx-24">
              <Button
                sx={{
                  padding: "2px 8px",
                  width: "100%",
                  background: "#EF0BB8",
                  borderRadius: "16px",
                  color: "white",
                  textTransform: "uppercase",
                  fontFamily: "Montserrat",
                  fontWeight: 300,
                }}
              >
                {t("qr.download")}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
