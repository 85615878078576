export function PercentIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM4 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm13 7a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm6.707-10.293a1 1 0 0 0-1.414-1.414l-13 13a1 1 0 1 0 1.414 1.414l13-13Z"
        fill="#EF0BB8"
      />
    </svg>
  );
}
