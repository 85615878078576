import React, { createContext, useContext, useState, useCallback } from "react";
import { AlertColor } from "@mui/material/Alert";

interface ToastContextType {
  showToast: (message: string, severity: AlertColor) => void;
  hideToast: () => void;
  toast: {
    open: boolean;
    message: string;
    severity: AlertColor;
    duration: number;
  };
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "info" as AlertColor,
    duration: 3000,
  });

  const showToast = useCallback(
    (
      message: string,
      severity: AlertColor = "info",
      duration: number = 6000
    ) => {
      setToast({ open: true, message, severity, duration });
    },
    []
  );

  const hideToast = useCallback(() => {
    setToast((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, hideToast, toast }}>
      {children}
    </ToastContext.Provider>
  );
}

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};
