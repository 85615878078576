import { Skeleton } from "@mui/material";

export function SidebarSkeleton() {
  return (
    <aside className="z-50 hidden max-h-screen gap-y-5 overflow-x-hidden rounded-3xl p-1 sm:p-4 bg-white sm:flex sm:flex-col w-52">
      {/* Header skeleton */}
      <div className="flex items-center justify-between">
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="circular" width={16} height={16} />
      </div>

      {/* Nombre usuario skeleton */}
      <div className="mt-4">
        <Skeleton width="60%" height={30} />
        <Skeleton width="40%" height={30} />
      </div>

      {/* Links skeleton */}
      <div className="mt-6 flex flex-1 flex-col gap-5">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="flex items-center gap-2">
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton width="60%" height={20} />
          </div>
        ))}
      </div>

      {/* User Data skeleton */}
      <div className="flex w-full items-center gap-2">
        <Skeleton variant="circular" width={40} height={40} />
        <div className="flex w-full flex-col">
          <Skeleton width="80%" height={20} />
          <Skeleton width="50%" height={15} />
        </div>
      </div>
    </aside>
  );
}
