import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Example = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard");
  }, [navigate]);

  return (
    <Box>
      <span style={{ fontSize: "24px", fontWeight: "600" }}>
        {t("example_traduction")}
      </span>
    </Box>
  );
};

export default Example;
