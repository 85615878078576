import { AddGroup } from "../components/teams/add-group";
import { EmployeeItem } from "../components/teams/employee-item";
import { useTranslation } from "react-i18next";
import { AddBranch } from "../components/branches/add-branch";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../context/toast-context";
import { AddEmployee } from "../components/teams/add-employee";
import { Loading } from "../components/Loading";
import { UserService } from "../services/user-service";
import { EmployeeUser } from "../types";

export function Teams() {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState<EmployeeUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const userService = useMemo(() => new UserService(), []);

  const handleUpdateEmployees = (updatedEmployees: EmployeeUser[]) => {
    setEmployees(() => [...updatedEmployees]);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchEmployees = async () => {
      setIsLoading(true);

      try {
        await userService.getEmployees(
          { limit: 100, offset: 0 },
          (data) => {
            if (!signal.aborted) {
              setEmployees(data.users);
            }
          },
          (errorMessage) => {
            if (!signal.aborted) {
              showToast(errorMessage, "error");
            }
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    fetchEmployees();
    return () => {
      controller.abort();
    };
  }, [showToast, userService]);

  return (
    <main className="h-full flex flex-col rounded-3xl bg-[#a0abba] p-6 overflow-hidden">
      {/* Contenido fijo o encabezado */}
      <div className="flex mb-4 gap-2  justify-end">
        <AddBranch />
        <AddGroup showBranches={true} />
        <AddEmployee onAddEmployees={handleUpdateEmployees} />
      </div>

      {/* Contenido scrollable */}
      <div className="flex flex-col flex-1 gap-2 h-full overflow-y-auto custom-scrollbar rounded-lg p-4">
        {isLoading && <Loading />}
        {!isLoading && employees.length === 0 && (
          <div className="w-full h-full flex justify-center items-center text-white text-2xl">
            <p>{t("employee.empty")}</p>
          </div>
        )}
        {!isLoading &&
          employees.length > 0 &&
          employees.map((employee) => (
            <EmployeeItem
              onUpdateEmployees={handleUpdateEmployees}
              employee={employee}
              key={employee.userId}
            />
          ))}
      </div>
    </main>
  );
}
