import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/img/logos/X.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { UserService } from "../services/user-service";
import { UnverifiedUser } from "../services/dto/response/user-response-dto";

export function RecoverPassword() {
  const [manager, setManager] = useState<UnverifiedUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userService = useMemo(() => new UserService(), []);

  const userid = params.get("userid");
  const token = params.get("token");

  const redirect = () => {
    navigate("/change-password", {
      state: {
        userid,
        token,
      },
    });
  };

  useEffect(() => {
    const fetchManager = async () => {
      const controller = new AbortController();
      const { signal } = controller;

      try {
        await userService.verifyChangePassword(
          {
            token: token!,
            userid: Number(userid!),
          },
          (data) => {
            setManager(data);
          },
          (error) => {
            navigate("/login");
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    if (userid && token) {
      fetchManager();
    } else {
      navigate("/login");
    }
  }, [token, userid, userService, navigate]);

  return (
    <main className="h-screen container mx-auto">
      <div className="flex flex-col mx-auto justify-center items-center pt-32">
        <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
        {isLoading && (
          <div className="mt-20 text-xl font-semibold">{t("loading")}</div>
        )}
        {!isLoading && manager && (
          <>
            <h1 className="font-normal max-w-xl text-center tracking-wide text-5xl pt-14">
              {t("recover-password.title")}
            </h1>

            <p className="py-10 text-center font-medium max-w-sm">
              {t("recover-password.message")}
            </p>
            <button
              onClick={redirect}
              className="px-24 hover:bg-black/90 mx-auto py-1 text-white bg-black rounded-xl"
            >
              Link
            </button>
          </>
        )}
      </div>
    </main>
  );
}
