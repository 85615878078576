import { Skeleton } from "@mui/material";

export function BranchesMetricsSkeleton() {
  return (
    <ul className="flex gap-4 p-2 h-full">
      {[...Array(5)].map((_, index) => (
        <li
          key={index}
          className="flex-shrink-0 w-72 p-2 bg-[#e9ebf2] rounded-md"
        >
          <article className="flex flex-col items-start gap-2">
            <div className="w-full flex items-center gap-2">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={40} height={20} />
              <Skeleton variant="text" width={30} height={15} />
            </div>
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="rectangular" width="100%" height={1} />
            <Skeleton variant="text" width="70%" height={20} />
          </article>
        </li>
      ))}
    </ul>
  );
}
