interface GroupProps {
  fill?: string;
}

export function Group({ fill = "#000" }: GroupProps) {
  return (
    <svg
      fill={fill}
      width="28"
      height="28"
      viewBox="-0.8 -0.8 17.6 17.6"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#fff"
      strokeWidth="0"
    >
      <path
        data-name="Path 183"
        d="M14.167 12.1A2 2 0 0 0 14.5 11 2 2 0 0 0 13 9.071V8.5a.5.5 0 0 0-.5-.5h-4V7H10a1.5 1.5 0 0 0 1.5-1.5 2.5 2.5 0 0 0-1.833-2.4A2 2 0 0 0 10 2a2 2 0 0 0-2-2 2 2 0 0 0-2 2 2 2 0 0 0 .333 1.1A2.5 2.5 0 0 0 4.5 5.5 1.5 1.5 0 0 0 6 7h1.5v1h-4a.5.5 0 0 0-.5.5v.571A2 2 0 0 0 1.5 11a2 2 0 0 0 .333 1.1A2.5 2.5 0 0 0 0 14.5 1.5 1.5 0 0 0 1.5 16h4A1.5 1.5 0 0 0 7 14.5a2.5 2.5 0 0 0-1.833-2.4A2 2 0 0 0 5.5 11 2 2 0 0 0 4 9.071V9h8v.071A2 2 0 0 0 10.5 11a2 2 0 0 0 .333 1.1A2.5 2.5 0 0 0 9 14.5a1.5 1.5 0 0 0 1.5 1.5h4a1.5 1.5 0 0 0 1.5-1.5 2.5 2.5 0 0 0-1.833-2.4ZM8 1a1 1 0 0 1 1 1 1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1ZM5.5 5.5A1.5 1.5 0 0 1 7 4h2a1.5 1.5 0 0 1 1.5 1.5.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5Zm.5 9a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5A1.5 1.5 0 0 1 2.5 13h2A1.5 1.5 0 0 1 6 14.5ZM4.5 11a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1Zm8-1a1 1 0 0 1 1 1 1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1Zm2 5h-4a.5.5 0 0 1-.5-.5 1.5 1.5 0 0 1 1.5-1.5h2a1.5 1.5 0 0 1 1.5 1.5.5.5 0 0 1-.5.5Z"
        stroke="none"
      />
    </svg>
  );
}
