import { Outlet } from "react-router-dom";
import { Sidebar } from "./components/Sidebar";

export function DashboardLayout() {
  return (
    <div className="h-screen bg-[#e9ebf2] flex gap-6 p-4 justify-between w-full">
      <Sidebar />

      <div className="h-full flex-1 overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}
