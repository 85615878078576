import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BranchSelector } from "../branches/branch-selector";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

export function BranchSelectorModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBranchSelect = (branchId: number) => {
    setSelectedBranch(branchId);
  };

  const handleConfirm = () => {
    if (selectedBranch) {
      navigate(`/dashboard/groups/${selectedBranch}`);
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setIsModalOpen(true)}
        sx={{
          background: "#2d2f32",
          margin: 0,
          padding: "0 10px",
          borderRadius: "999px",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
        }}
      >
        {t("groups.change-branch")}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby={t("groups.select.title")}
          aria-describedby={t("groups.select.title")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("groups.select.title")}</h1>
            </div>
            <div className="p-8">
              <BranchSelector
                onBranchSelect={handleBranchSelect}
                selectedBranch={selectedBranch}
                sidebarLoader={false}
              />
              <div className="flex justify-center mt-12">
                <Button
                  onClick={handleConfirm}
                  disabled={!selectedBranch}
                  sx={{
                    padding: "4px 8px",
                    width: "100%",
                    background: "#EF0BB8",
                    borderRadius: "16px",
                    color: "white",
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    fontWeight: 300,
                    "&.Mui-disabled": {
                      opacity: "40%",
                      color: "#fff",
                    },
                  }}
                >
                  {t("groups.select.submit")}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
