import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Legend } from "./legend";
import { DashboardFilter } from "./dashboard-filter";
import { useState } from "react";
import { DashboardFilterOption } from "../../types";

export function ChartMetrics() {
  const [filter, setFilter] = useState<DashboardFilterOption>("hour");

  const handleFilterChange = (newFilter: DashboardFilterOption) => {
    setFilter(newFilter);
  };
  const data = [
    { time: "09HS", propinas: 5000, valoraciones: 10000 },
    { time: "11HS", propinas: 10000, valoraciones: 15000 },
    { time: "13HS", propinas: 15000, valoraciones: 20000 },
    { time: "15HS", propinas: 5000, valoraciones: 10000 },
    { time: "17HS", propinas: 20000, valoraciones: 20000 },
    { time: "19HS", propinas: 30000, valoraciones: 30000 },
    { time: "21HS", propinas: 10000, valoraciones: 25000 },
  ];

  const formatYAxis = (value: any) => {
    if (value > 1000) {
      return `${value / 1000}k`;
    }
    return value;
  };

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-4 gap-4">
      <div className="flex justify-end pb-4"></div>
      <div className="flex gap-2 flex-grow">
        <ResponsiveContainer width="80%" height="100%">
          <LineChart data={data}>
            <XAxis dy={12} className="text-sm font-semibold" dataKey="time" />
            <YAxis
              tickFormatter={formatYAxis}
              className="text-sm font-semibold"
              type="number"
              domain={[0, "dataMax"]}
              axisLine={false}
              tickLine={false}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="valoraciones"
              stroke="#F3C433"
              strokeWidth="2px"
            />
            <Line
              type="monotone"
              dataKey="propinas"
              stroke="#EF0BB8"
              strokeWidth="2px"
            />
          </LineChart>
        </ResponsiveContainer>
        <div className="flex h-full flex-col justify-between ml-5">
          <Legend />
          <DashboardFilter value={filter} onChange={handleFilterChange} />
        </div>
      </div>
    </div>
  );
}
