export function Legend() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-1">
        <div className="h-6 w-6 rounded-md bg-primary" />
        <span className="font-semibold text-gray-dark text-sm">Propinas</span>
      </div>
      <div className="flex items-center gap-1">
        <div className="h-6 w-6 rounded-md bg-[#F3C433]" />
        <span className="font-semibold text-gray-dark text-sm">
          Valoraciones
        </span>
      </div>
    </div>
  );
}
