import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useToast } from "../context/toast-context";

function Toast() {
  const { toast, hideToast } = useToast();
  return (
    <Snackbar
      open={toast.open}
      autoHideDuration={toast.duration}
      onClose={hideToast}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={hideToast} severity={toast.severity}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
