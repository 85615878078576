import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useToast } from "../../context/toast-context";

interface ShareEmployeeProps {
  link: string;
}

export function ShareEmployee({ link }: ShareEmployeeProps) {
  const { t } = useTranslation();
  const { showToast } = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    showToast(t("clipboard"), "success");
  };

  return (
    <>
      <Button
        title={t("share-qr")}
        onClick={copyToClipboard}
        sx={{
          margin: 0,
          minWidth: "fit-content",
          background: "transparent",
          border: "1px solid #EF0BB8",
          borderRadius: "50%",
          color: "#EF0BB8",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          padding: "0.25rem",
          width: "1.7rem",
          height: "1.7rem",
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.803 5.333c0-1.84 1.5-3.333 3.348-3.333A3.341 3.341 0 0 1 20.5 5.333c0 1.841-1.5 3.334-3.349 3.334-.933 0-1.777-.381-2.384-.994l-4.635 3.156a3.336 3.336 0 0 1-.182 1.917l5.082 3.34a3.346 3.346 0 0 1 2.12-.753 3.341 3.341 0 0 1 3.348 3.334C20.5 20.507 19 22 17.151 22a3.341 3.341 0 0 1-3.348-3.333c0-.483.103-.942.289-1.356L9.05 14a3.346 3.346 0 0 1-2.202.821A3.34 3.34 0 0 1 3.5 11.487a3.34 3.34 0 0 1 3.348-3.333c1.064 0 2.01.493 2.623 1.261l4.493-3.059a3.317 3.317 0 0 1-.161-1.023Z"
            fill="#EF0BB8"
          />
        </svg>
      </Button>
    </>
  );
}
